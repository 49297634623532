import { supportedChains } from './supported-chains'
import { BaseToken } from './tokens'

export const ALL_TOKENS = [
  {
    id: '3-dpass-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'P3D',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1',
    chain: {
      id: '3-dpass',
    },
  },
  {
    id: 'acala-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'ACA',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/acala.svg',
    existentialDeposit: '100000000000',
    chain: {
      id: 'acala',
    },
    coingeckoId: 'acala',
  },
  {
    id: 'acala-substrate-tokens-ape',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'APE',
    decimals: 18,
    existentialDeposit: '3000000000000000',
    onChainId: '{"Erc20":"0xf4c723e61709d90f89939c1852f516e373d418a8"}',
    chain: {
      id: 'acala',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'acala-substrate-tokens-aseed',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'aSEED',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/aseed.svg',
    existentialDeposit: '100000000000',
    onChainId: '{"Token":"AUSD"}',
    chain: {
      id: 'acala',
    },
    coingeckoId: 'ausd-seed-acala',
  },
  {
    id: 'acala-substrate-tokens-astr',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'ASTR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/astar.webp',
    existentialDeposit: '100000000000000000',
    onChainId: '{"ForeignAsset":2}',
    chain: {
      id: 'acala',
    },
    // TODO: check if this is wrong
    coingeckoId: 'astar',
  },
  {
    id: 'acala-substrate-tokens-dai',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'DAI',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/dai.webp',
    existentialDeposit: '10000000000000000',
    onChainId: '{"Erc20":"0x54a37a01cd75b616d63e0ab665bffdb0143c52ae"}',
    chain: {
      id: 'acala',
    },
    coingeckoId: 'dai',
  },
  {
    id: 'acala-substrate-tokens-dot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'DOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/dot.svg',
    existentialDeposit: '100000000',
    onChainId: '{"Token":"DOT"}',
    chain: {
      id: 'acala',
    },
    coingeckoId: 'polkadot',
  },
  {
    id: 'acala-substrate-tokens-eq',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'EQ',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/eq.svg',
    existentialDeposit: '1000000000',
    onChainId: '{"ForeignAsset":7}',
    chain: {
      id: 'acala',
    },
    coingeckoId: 'equilibrium-token',
  },
  {
    id: 'acala-substrate-tokens-eqd',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'EQD',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/eqd.svg',
    existentialDeposit: '1000000000',
    onChainId: '{"ForeignAsset":8}',
    chain: {
      id: 'acala',
    },
  },
  {
    id: 'acala-substrate-tokens-glmr',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'GLMR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/moonbeam.webp',
    existentialDeposit: '100000000000000000',
    onChainId: '{"ForeignAsset":0}',
    chain: {
      id: 'acala',
    },
    coingeckoId: 'moonbeam',
  },
  {
    id: 'acala-substrate-tokens-ibtc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'iBTC',
    decimals: 8,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ibtc.svg',
    existentialDeposit: '100',
    onChainId: '{"ForeignAsset":3}',
    chain: {
      id: 'acala',
    },
    coingeckoId: 'interbtc',
  },
  {
    id: 'acala-substrate-tokens-intr',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'INTR',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/intr.svg',
    existentialDeposit: '1000000000',
    onChainId: '{"ForeignAsset":4}',
    chain: {
      id: 'acala',
    },
    coingeckoId: 'interlay',
  },
  {
    id: 'acala-substrate-tokens-lcdot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lcDOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/lcdot.svg',
    existentialDeposit: '100000000',
    onChainId: '{"LiquidCrowdloan":13}',
    chain: {
      id: 'acala',
    },
    coingeckoId: 'liquid-crowdloan-dot',
  },
  {
    id: 'acala-substrate-tokens-ldot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'LDOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ldot.svg',
    existentialDeposit: '500000000',
    onChainId: '{"Token":"LDOT"}',
    chain: {
      id: 'acala',
    },
    coingeckoId: 'liquid-staking-dot',
  },
  {
    id: 'acala-substrate-tokens-lp-aca-aseed',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp ACA-aSEED',
    decimals: 12,
    existentialDeposit: '100000000000',
    onChainId: '{"DexShare":[{"Token":"ACA"},{"Token":"AUSD"}]}',
    chain: {
      id: 'acala',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'acala-substrate-tokens-lp-aseed-ibtc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp aSEED-iBTC',
    decimals: 12,
    existentialDeposit: '100000000000',
    onChainId: '{"DexShare":[{"Token":"AUSD"},{"ForeignAsset":3}]}',
    chain: {
      id: 'acala',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'acala-substrate-tokens-lp-aseed-intr',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp aSEED-INTR',
    decimals: 12,
    existentialDeposit: '100000000000',
    onChainId: '{"DexShare":[{"Token":"AUSD"},{"ForeignAsset":4}]}',
    chain: {
      id: 'acala',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'acala-substrate-tokens-lp-aseed-lcdot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp aSEED-lcDOT',
    decimals: 12,
    existentialDeposit: '100000000000',
    onChainId: '{"DexShare":[{"Token":"AUSD"},{"LiquidCrowdloan":13}]}',
    chain: {
      id: 'acala',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'acala-substrate-tokens-lp-aseed-ldot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp aSEED-LDOT',
    decimals: 12,
    existentialDeposit: '100000000000',
    onChainId: '{"DexShare":[{"Token":"AUSD"},{"Token":"LDOT"}]}',
    chain: {
      id: 'acala',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'acala-substrate-tokens-lp-dot-lcdot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp DOT-lcDOT',
    decimals: 10,
    existentialDeposit: '100000000',
    onChainId: '{"DexShare":[{"Token":"DOT"},{"LiquidCrowdloan":13}]}',
    chain: {
      id: 'acala',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'acala-substrate-tokens-para',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'PARA',
    decimals: 12,
    existentialDeposit: '100000000000',
    onChainId: '{"ForeignAsset":1}',
    chain: {
      id: 'acala',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'acala-substrate-tokens-pha',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'PHA',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/pha.webp',
    existentialDeposit: '100000000000',
    onChainId: '{"ForeignAsset":9}',
    chain: {
      id: 'acala',
    },
    coingeckoId: 'pha',
  },
  {
    id: 'acala-substrate-tokens-tap',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'TAP',
    decimals: 12,
    existentialDeposit: '1000000000000',
    onChainId: '{"Token":"TAP"}',
    chain: {
      id: 'acala',
    },
    coingeckoId: 'tapio-protocol',
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'acala-substrate-tokens-tdot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'tDOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/tdot.svg',
    existentialDeposit: '100000000',
    onChainId: '{"StableAssetPoolToken":0}',
    chain: {
      id: 'acala',
    },
  },
  {
    id: 'acala-substrate-tokens-usdcet',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDCet',
    decimals: 6,
    existentialDeposit: '10000',
    onChainId: '{"Erc20":"0x07df96d1341a7d16ba1ad431e2c847d978bc2bce"}',
    chain: {
      id: 'acala',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'acala-substrate-tokens-wbtc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'WBTC',
    decimals: 8,
    existentialDeposit: '60',
    onChainId: '{"Erc20":"0xc80084af223c8b598536178d9361dc55bfda6818"}',
    chain: {
      id: 'acala',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'acala-substrate-tokens-weth',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'WETH',
    decimals: 18,
    existentialDeposit: '8500000000000',
    onChainId: '{"Erc20":"0x5a4d6acdc4e3e5ab15717f407afe957f7a242578"}',
    chain: {
      id: 'acala',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'acurast-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'cACU',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'acurast',
    },
  },
  {
    id: 'ajuna-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'AJUN',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/ajuna.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'ajuna',
    },
  },
  {
    id: 'ajuna-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'AJUN',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '100000',
    chain: {
      id: 'ajuna-testnet',
    },
  },
  {
    id: 'aleph-zero-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'AZERO',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/aleph-zero.svg',
    existentialDeposit: '500',
    chain: {
      id: 'aleph-zero',
    },
    coingeckoId: 'aleph-zero',
  },
  {
    id: 'aleph-zero-substrate-psp22-ahero',
    type: 'substrate-psp22',
    isTestnet: false,
    isDefault: true,
    symbol: 'AHERO',
    decimals: 12,
    existentialDeposit: '0',
    contractAddress: '5DDvG1bTGhWk8P9fZDcdp47TykhwGV46eKjr54SPcX1yTaRg',
    chain: {
      id: 'aleph-zero',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'aleph-zero-substrate-psp22-ainu',
    type: 'substrate-psp22',
    isTestnet: false,
    isDefault: true,
    symbol: 'AINU',
    decimals: 6,
    existentialDeposit: '0',
    contractAddress: '5CSAAAbQpPeY1ieqRS7LynmuMHVJdthq8nxt2S4L6qBGcdnF',
    chain: {
      id: 'aleph-zero',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'aleph-zero-substrate-psp22-degen',
    type: 'substrate-psp22',
    isTestnet: false,
    isDefault: true,
    symbol: 'DEGEN',
    decimals: 12,
    existentialDeposit: '0',
    contractAddress: '5E3xgSL2kLA56Z7ykNwoGB4a9sdaYRbCBxRF4cDLQYhZU8bv',
    chain: {
      id: 'aleph-zero',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'aleph-zero-substrate-psp22-inw',
    type: 'substrate-psp22',
    isTestnet: false,
    isDefault: true,
    symbol: 'INW',
    decimals: 12,
    existentialDeposit: '0',
    contractAddress: '5H4aCwLKUpVpct6XGJzDGPPXFockNKQU2JUVNgUw6BXEPzST',
    chain: {
      id: 'aleph-zero',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'aleph-zero-substrate-psp22-iou',
    type: 'substrate-psp22',
    isTestnet: false,
    isDefault: true,
    symbol: 'IOU',
    decimals: 12,
    existentialDeposit: '0',
    contractAddress: '5GYgJ1xBPtyUwbPVnDfbg9uRGWdGrcaM6y1TaftUMoxUHQh5',
    chain: {
      id: 'aleph-zero',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'aleph-zero-substrate-psp22-panx',
    type: 'substrate-psp22',
    isTestnet: false,
    isDefault: true,
    symbol: 'PANX',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/panorama-swap-token.webp',
    existentialDeposit: '0',
    contractAddress: '5GSGAcvqpF5SuH2MhJ1YUdbLAbssCjeqCn2miMUCWUjnr5DQ',
    chain: {
      id: 'aleph-zero',
    },
    coingeckoId: 'panorama-swap-token',
  },
  {
    id: 'aleph-zero-substrate-psp22-pete',
    type: 'substrate-psp22',
    isTestnet: false,
    isDefault: true,
    symbol: 'PETE',
    decimals: 12,
    existentialDeposit: '0',
    contractAddress: '5FyVkDZi86awxJsXnJa2a1TeXwnMdhXmcQ2pmKLNmUgYRNM7',
    chain: {
      id: 'aleph-zero',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'aleph-zero-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'TZERO',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/aleph-zero-testnet.svg',
    existentialDeposit: '500',
    chain: {
      id: 'aleph-zero-testnet',
    },
  },
  {
    id: 'altair-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'AIR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/altair.svg',
    existentialDeposit: '1000000000000',
    chain: {
      id: 'altair',
    },
    coingeckoId: 'altair',
  },
  {
    id: 'amplitude-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'AMPE',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/amplitude.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'amplitude',
    },
  },
  {
    id: 'amplitude-substrate-tokens-ksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'KSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ksm.svg',
    existentialDeposit: '0',
    onChainId: '{"XCM":0}',
    chain: {
      id: 'amplitude',
    },
    coingeckoId: 'kusama',
  },
  {
    id: 'amplitude-substrate-tokens-usdt',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDT',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/usdt.svg',
    existentialDeposit: '0',
    onChainId: '{"XCM":1}',
    chain: {
      id: 'amplitude',
    },
    coingeckoId: 'tether',
  },
  {
    id: 'ares-odyssey-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'ARES',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/ares-odyssey.svg',
    existentialDeposit: '500',
    chain: {
      id: 'ares-odyssey',
    },
    coingeckoId: 'ares-protocol',
  },
  {
    id: 'astar-substrate-assets-18446744073709551616-aca',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'ACA',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/aca.svg',
    existentialDeposit: '1',
    assetId: '18446744073709551616',
    isFrozen: false,
    chain: {
      id: 'astar',
    },
    coingeckoId: 'acala',
  },
  {
    id: 'astar-substrate-assets-18446744073709551617-aseed',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'aSEED',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/aseed.svg',
    existentialDeposit: '1',
    assetId: '18446744073709551617',
    isFrozen: false,
    chain: {
      id: 'astar',
    },
    coingeckoId: 'ausd-seed-acala',
  },
  {
    id: 'astar-substrate-assets-18446744073709551618-ldot',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'LDOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ldot.svg',
    existentialDeposit: '1',
    assetId: '18446744073709551618',
    isFrozen: false,
    chain: {
      id: 'astar',
    },
    coingeckoId: 'liquid-staking-dot',
  },
  {
    id: 'astar-substrate-assets-18446744073709551619-glmr',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'GLMR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/moonbeam.webp',
    existentialDeposit: '1',
    assetId: '18446744073709551619',
    isFrozen: false,
    chain: {
      id: 'astar',
    },
    coingeckoId: 'moonbeam',
  },
  {
    id: 'astar-substrate-assets-18446744073709551620-ibtc',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'iBTC',
    decimals: 8,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ibtc.svg',
    existentialDeposit: '1',
    assetId: '18446744073709551620',
    isFrozen: false,
    chain: {
      id: 'astar',
    },
    coingeckoId: 'interbtc',
  },
  {
    id: 'astar-substrate-assets-18446744073709551621-intr',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'INTR',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/intr.svg',
    existentialDeposit: '1',
    assetId: '18446744073709551621',
    isFrozen: false,
    chain: {
      id: 'astar',
    },
    coingeckoId: 'interlay',
  },
  {
    id: 'astar-substrate-assets-18446744073709551622-pha',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'PHA',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/pha.webp',
    existentialDeposit: '1',
    assetId: '18446744073709551622',
    isFrozen: false,
    chain: {
      id: 'astar',
    },
    coingeckoId: 'pha',
  },
  {
    id: 'astar-substrate-assets-18446744073709551623-bnc',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'BNC',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/bnc.svg',
    existentialDeposit: '1',
    assetId: '18446744073709551623',
    isFrozen: false,
    chain: {
      id: 'astar',
    },
    coingeckoId: 'bifrost-native-coin',
  },
  {
    id: 'astar-substrate-assets-18446744073709551624-vdot',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'vDOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/vdot.svg',
    existentialDeposit: '1',
    assetId: '18446744073709551624',
    isFrozen: false,
    chain: {
      id: 'astar',
    },
    coingeckoId: 'voucher-dot',
  },
  {
    id: 'astar-substrate-assets-18446744073709551625-clv',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'CLV',
    decimals: 18,
    existentialDeposit: '1',
    assetId: '18446744073709551625',
    isFrozen: false,
    chain: {
      id: 'astar',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'astar-substrate-assets-18446744073709551626-vsdot',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'VSDOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/vsdot.svg',
    existentialDeposit: '1',
    assetId: '18446744073709551626',
    isFrozen: false,
    chain: {
      id: 'astar',
    },
  },
  {
    id: 'astar-substrate-assets-18446744073709551627-ring',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'RING',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ring.svg',
    existentialDeposit: '1',
    assetId: '18446744073709551627',
    isFrozen: false,
    chain: {
      id: 'astar',
    },
    coingeckoId: 'darwinia-network-native-token',
  },
  {
    id: 'astar-substrate-assets-18446744073709551628-eq',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'EQ',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/eq.svg',
    existentialDeposit: '1',
    assetId: '18446744073709551628',
    isFrozen: false,
    chain: {
      id: 'astar',
    },
    coingeckoId: 'equilibrium-token',
  },
  {
    id: 'astar-substrate-assets-18446744073709551633-pink',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'PINK',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/pink.png',
    existentialDeposit: '1',
    assetId: '18446744073709551633',
    isFrozen: false,
    chain: {
      id: 'astar',
    },
  },
  {
    id: 'astar-substrate-assets-18446744073709551629-eqd',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'EQD',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/eqd.svg',
    existentialDeposit: '1',
    assetId: '18446744073709551629',
    isFrozen: false,
    chain: {
      id: 'astar',
    },
  },
  {
    id: 'astar-substrate-assets-340282366920938463463374607431768211455-dot',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'DOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/dot.svg',
    existentialDeposit: '1',
    assetId: '340282366920938463463374607431768211455',
    isFrozen: false,
    chain: {
      id: 'astar',
    },
    coingeckoId: 'polkadot',
  },
  {
    id: 'astar-substrate-assets-4294969280-usdt',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDT',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/usdt.svg',
    existentialDeposit: '1',
    assetId: '4294969280',
    isFrozen: false,
    chain: {
      id: 'astar',
    },
    coingeckoId: 'tether',
  },
  {
    id: 'astar-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'ASTR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/astar.svg',
    existentialDeposit: '1000000',
    chain: {
      id: 'astar',
    },
    coingeckoId: 'astar',
  },
  {
    id: 'automata-contextfree-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'CTX',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    chain: {
      id: 'automata-contextfree-testnet',
    },
  },
  {
    id: 'automata-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'ATA',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/automata.webp',
    existentialDeposit: '0',
    chain: {
      id: 'automata',
    },
    coingeckoId: 'automata',
  },
  {
    id: 'avail-goldberg-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'AVL',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '10000000000000',
    chain: {
      id: 'avail-goldberg-testnet',
    },
  },
  {
    id: 'avail-turing-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'AVAIL',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '10000000000000',
    chain: {
      id: 'avail-turing-testnet',
    },
  },
  {
    id: 'aventus-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'AVT',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    chain: {
      id: 'aventus',
    },
  },
  {
    id: 'bajun-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'BAJU',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/bajun.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'bajun',
    },
    coingeckoId: 'ajuna-network',
  },
  {
    id: 'basilisk-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'BSX',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/basilisk.svg',
    existentialDeposit: '1000000000000',
    chain: {
      id: 'basilisk',
    },
    coingeckoId: 'basilisk',
  },
  {
    id: 'basilisk-substrate-tokens-aseed',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'aSEED',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/aseed.svg',
    existentialDeposit: '10000000000',
    onChainId: 2,
    chain: {
      id: 'basilisk',
    },
    coingeckoId: 'ausd-seed-acala',
  },
  {
    id: 'basilisk-substrate-tokens-dai',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'DAI',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/dai.webp',
    existentialDeposit: '10000000000',
    onChainId: 13,
    chain: {
      id: 'basilisk',
    },
    coingeckoId: 'dai',
  },
  {
    id: 'basilisk-substrate-tokens-ksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'KSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ksm.svg',
    existentialDeposit: '100000000',
    onChainId: 1,
    chain: {
      id: 'basilisk',
    },
    coingeckoId: 'kusama',
  },
  {
    id: 'basilisk-substrate-tokens-tnkr',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'TNKR',
    decimals: 12,
    existentialDeposit: '1000000000',
    onChainId: 6,
    chain: {
      id: 'basilisk',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'basilisk-substrate-tokens-usdcet',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDCet',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/usd-coin-wormhole-from-ethereum.webp',
    existentialDeposit: '10000',
    onChainId: 9,
    chain: {
      id: 'basilisk',
    },
    coingeckoId: 'usd-coin-wormhole-from-ethereum',
  },
  {
    id: 'basilisk-substrate-tokens-usdt',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDT',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/usdt.svg',
    existentialDeposit: '10000',
    onChainId: 14,
    chain: {
      id: 'basilisk',
    },
    coingeckoId: 'tether',
  },
  {
    id: 'basilisk-substrate-tokens-wbtc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'wBTC',
    decimals: 8,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/bitcoin.webp',
    existentialDeposit: '1000000',
    onChainId: 11,
    chain: {
      id: 'basilisk',
    },
    coingeckoId: 'bitcoin',
  },
  {
    id: 'basilisk-substrate-tokens-weth',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'wETH',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/ethereum.webp',
    existentialDeposit: '10000000',
    onChainId: 10,
    chain: {
      id: 'basilisk',
    },
    coingeckoId: 'ethereum',
  },
  {
    id: 'basilisk-substrate-tokens-wusdt',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'wUSDT',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/tether.webp',
    existentialDeposit: '10000000000',
    onChainId: 12,
    chain: {
      id: 'basilisk',
    },
    coingeckoId: 'tether',
  },
  {
    id: 'bifrost-kusama-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'BNC',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/bifrost-kusama.svg',
    existentialDeposit: '10000000000',
    chain: {
      id: 'bifrost-kusama',
    },
    coingeckoId: 'bifrost-native-coin',
  },
  {
    id: 'bifrost-kusama-substrate-tokens-aseed',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'aSEED',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/aseed.svg',
    existentialDeposit: '10000000000',
    onChainId: '{"Stable":"KUSD"}',
    chain: {
      id: 'bifrost-kusama',
    },
    coingeckoId: 'ausd-seed-karura',
  },
  {
    id: 'bifrost-kusama-substrate-tokens-blp0',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'BLP0',
    decimals: 12,
    existentialDeposit: '1000000',
    onChainId: '{"BLP":0}',
    chain: {
      id: 'bifrost-kusama',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'bifrost-kusama-substrate-tokens-blp1',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'BLP1',
    decimals: 12,
    existentialDeposit: '1000000',
    onChainId: '{"BLP":1}',
    chain: {
      id: 'bifrost-kusama',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'bifrost-kusama-substrate-tokens-blp2',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'BLP2',
    decimals: 12,
    existentialDeposit: '1000000',
    onChainId: '{"BLP":2}',
    chain: {
      id: 'bifrost-kusama',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'bifrost-kusama-substrate-tokens-blp3',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'BLP3',
    decimals: 12,
    existentialDeposit: '1000000',
    onChainId: '{"BLP":3}',
    chain: {
      id: 'bifrost-kusama',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'bifrost-kusama-substrate-tokens-bnc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'BNC',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/bnc.svg',
    existentialDeposit: '0',
    onChainId: '{"Token":"BNC"}',
    chain: {
      id: 'bifrost-kusama',
    },
    coingeckoId: 'bifrost-native-coin',
  },
  {
    id: 'bifrost-kusama-substrate-tokens-dot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'DOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/dot.svg',
    existentialDeposit: '0',
    onChainId: '{"Token":"DOT"}',
    chain: {
      id: 'bifrost-kusama',
    },
    coingeckoId: 'polkadot',
  },
  {
    id: 'bifrost-kusama-substrate-tokens-kar',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'KAR',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/kar.svg',
    existentialDeposit: '100000000',
    onChainId: '{"Token":"KAR"}',
    chain: {
      id: 'bifrost-kusama',
    },
    coingeckoId: 'karura',
  },
  {
    id: 'bifrost-kusama-substrate-tokens-kbtc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'kBTC',
    decimals: 8,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/kbtc.svg',
    existentialDeposit: '100',
    onChainId: '{"Token2":2}',
    chain: {
      id: 'bifrost-kusama',
    },
    coingeckoId: 'kintsugi-btc',
  },
  {
    id: 'bifrost-kusama-substrate-tokens-kint',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'KINT',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/kint.svg',
    existentialDeposit: '10000000000',
    onChainId: '{"Token2":1}',
    chain: {
      id: 'bifrost-kusama',
    },
    coingeckoId: 'kintsugi',
  },
  {
    id: 'bifrost-kusama-substrate-tokens-ksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'KSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ksm.svg',
    existentialDeposit: '100000000',
    onChainId: '{"Token":"KSM"}',
    chain: {
      id: 'bifrost-kusama',
    },
    coingeckoId: 'kusama',
  },
  {
    id: 'bifrost-kusama-substrate-tokens-mgx',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'MGX',
    decimals: 18,
    existentialDeposit: '10000000000000',
    onChainId: '{"Token2":4}',
    chain: {
      id: 'bifrost-kusama',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'bifrost-kusama-substrate-tokens-movr',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'MOVR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/moonriver.webp',
    existentialDeposit: '1000000000000',
    onChainId: '{"Token":"MOVR"}',
    chain: {
      id: 'bifrost-kusama',
    },
    coingeckoId: 'moonriver',
  },
  {
    id: 'bifrost-kusama-substrate-tokens-pha',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'PHA',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/pha.webp',
    existentialDeposit: '40000000000',
    onChainId: '{"Token":"PHA"}',
    chain: {
      id: 'bifrost-kusama',
    },
    coingeckoId: 'pha',
  },
  {
    id: 'bifrost-kusama-substrate-tokens-rmrk',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'RMRK',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/rmrk.svg',
    existentialDeposit: '10000',
    onChainId: '{"Token":"RMRK"}',
    chain: {
      id: 'bifrost-kusama',
    },
    coingeckoId: 'rmrk',
  },
  {
    id: 'bifrost-kusama-substrate-tokens-sdn',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'SDN',
    decimals: 18,
    existentialDeposit: '10000000000000000',
    onChainId: '{"Token2":3}',
    chain: {
      id: 'bifrost-kusama',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'bifrost-kusama-substrate-tokens-usdt',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDT',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/usdt.svg',
    existentialDeposit: '10000',
    onChainId: '{"Token2":0}',
    chain: {
      id: 'bifrost-kusama',
    },
    coingeckoId: 'tether',
  },
  {
    id: 'bifrost-kusama-substrate-tokens-vbnc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'vBNC',
    decimals: 12,
    existentialDeposit: '10000000000',
    onChainId: '{"VToken":"BNC"}',
    chain: {
      id: 'bifrost-kusama',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'bifrost-kusama-substrate-tokens-vksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'vKSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/vksm.svg',
    existentialDeposit: '100000000',
    onChainId: '{"VToken":"KSM"}',
    chain: {
      id: 'bifrost-kusama',
    },
  },
  {
    id: 'bifrost-kusama-substrate-tokens-vmovr',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'vMOVR',
    decimals: 18,
    existentialDeposit: '1000000000000',
    onChainId: '{"VToken":"MOVR"}',
    chain: {
      id: 'bifrost-kusama',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'bifrost-kusama-substrate-tokens-vsksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'vsKSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/vsksm.svg',
    existentialDeposit: '100000000',
    onChainId: '{"VSToken":"KSM"}',
    chain: {
      id: 'bifrost-kusama',
    },
  },
  {
    id: 'bifrost-kusama-substrate-tokens-zlk',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'ZLK',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/zlk.svg',
    existentialDeposit: '1000000000000',
    onChainId: '{"Token":"ZLK"}',
    chain: {
      id: 'bifrost-kusama',
    },
  },
  {
    id: 'bifrost-polkadot-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'BNC',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/bifrost-polkadot.svg',
    existentialDeposit: '10000000000',
    chain: {
      id: 'bifrost-polkadot',
    },
    coingeckoId: 'bifrost-native-coin',
  },
  {
    id: 'bifrost-polkadot-substrate-tokens-astr',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'ASTR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/astar.webp',
    existentialDeposit: '10000000000000000',
    onChainId: '{"Token2":3}',
    chain: {
      id: 'bifrost-polkadot',
    },
    coingeckoId: 'astar',
  },
  {
    id: 'bifrost-polkadot-substrate-tokens-bnc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'BNC',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/bnc.svg',
    existentialDeposit: '0',
    onChainId: '{"Token":"BNC"}',
    chain: {
      id: 'bifrost-polkadot',
    },
    coingeckoId: 'bifrost-native-coin',
  },
  {
    id: 'bifrost-polkadot-substrate-tokens-dot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'DOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/dot.svg',
    existentialDeposit: '1000000',
    onChainId: '{"Token2":0}',
    chain: {
      id: 'bifrost-polkadot',
    },
    coingeckoId: 'polkadot',
  },
  {
    id: 'bifrost-polkadot-substrate-tokens-fil',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'FIL',
    decimals: 18,
    existentialDeposit: '1000000000000',
    onChainId: '{"Token2":4}',
    chain: {
      id: 'bifrost-polkadot',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'bifrost-polkadot-substrate-tokens-glmr',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'GLMR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/moonbeam.webp',
    existentialDeposit: '1000000000000',
    onChainId: '{"Token2":1}',
    chain: {
      id: 'bifrost-polkadot',
    },
    coingeckoId: 'moonbeam',
  },
  {
    id: 'bifrost-polkadot-substrate-tokens-ibtc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'IBTC',
    decimals: 8,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ibtc.svg',
    existentialDeposit: '100',
    onChainId: '{"Token2":6}',
    chain: {
      id: 'bifrost-polkadot',
    },
    coingeckoId: 'interbtc',
  },
  {
    id: 'bifrost-polkadot-substrate-tokens-intr',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'INTR',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/intr.svg',
    existentialDeposit: '10000000',
    onChainId: '{"Token2":7}',
    chain: {
      id: 'bifrost-polkadot',
    },
    coingeckoId: 'interlay',
  },
  {
    id: 'bifrost-polkadot-substrate-tokens-usdc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDC',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/usd-coin.webp',
    existentialDeposit: '1000',
    onChainId: '{"Token2":5}',
    chain: {
      id: 'bifrost-polkadot',
    },
    coingeckoId: 'usd-coin',
  },
  {
    id: 'bifrost-polkadot-substrate-tokens-usdt',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDT',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/usdt.svg',
    existentialDeposit: '1000',
    onChainId: '{"Token2":2}',
    chain: {
      id: 'bifrost-polkadot',
    },
    coingeckoId: 'tether',
  },
  {
    id: 'bifrost-polkadot-substrate-tokens-vastr',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'vASTR',
    decimals: 18,
    existentialDeposit: '10000000000000000',
    onChainId: '{"VToken2":3}',
    chain: {
      id: 'bifrost-polkadot',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'bifrost-polkadot-substrate-tokens-vdot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'vDOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/vdot.svg',
    existentialDeposit: '1000000',
    onChainId: '{"VToken2":0}',
    chain: {
      id: 'bifrost-polkadot',
    },
    coingeckoId: 'voucher-dot',
  },
  {
    id: 'bifrost-polkadot-substrate-tokens-vfil',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'vFIL',
    decimals: 18,
    existentialDeposit: '1000000000000',
    onChainId: '{"VToken2":4}',
    chain: {
      id: 'bifrost-polkadot',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'bifrost-polkadot-substrate-tokens-vglmr',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'vGLMR',
    decimals: 18,
    existentialDeposit: '1000000000000',
    onChainId: '{"VToken2":1}',
    chain: {
      id: 'bifrost-polkadot',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'bifrost-polkadot-substrate-tokens-vsdot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'vsDOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/vsdot.svg',
    existentialDeposit: '1000000',
    onChainId: '{"VSToken2":0}',
    chain: {
      id: 'bifrost-polkadot',
    },
  },
  {
    id: 'bitcountry-pioneer-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'NEER',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/bitcountry-pioneer.svg',
    existentialDeposit: '100000000000000000',
    chain: {
      id: 'bitcountry-pioneer',
    },
    coingeckoId: 'metaverse-network-pioneer',
  },
  {
    id: 'bitcountry-pioneer-substrate-tokens-aseed',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'aSEED',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/aseed.svg',
    existentialDeposit: '0',
    onChainId: '{"Stable":0}',
    chain: {
      id: 'bitcountry-pioneer',
    },
    coingeckoId: 'ausd-seed-karura',
  },
  {
    id: 'bitcountry-pioneer-substrate-tokens-bit',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'BIT',
    decimals: 18,
    existentialDeposit: '0',
    onChainId: '{"MiningResource":0}',
    chain: {
      id: 'bitcountry-pioneer',
    },
    coingeckoId: 'bit',
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'bitcountry-pioneer-substrate-tokens-kar',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'KAR',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/kar.svg',
    existentialDeposit: '0',
    onChainId: '{"NativeToken":2}',
    chain: {
      id: 'bitcountry-pioneer',
    },
    coingeckoId: 'karura',
  },
  {
    id: 'bitcountry-pioneer-substrate-tokens-ksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'KSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ksm.svg',
    existentialDeposit: '0',
    onChainId: '{"NativeToken":1}',
    chain: {
      id: 'bitcountry-pioneer',
    },
    coingeckoId: 'kusama',
  },
  {
    id: 'bitgreen-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'BBB',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/bitgreen.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'bitgreen',
    },
  },
  {
    id: 'bittensor-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'TAO',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/bittensor.svg',
    existentialDeposit: '500',
    chain: {
      id: 'bittensor',
    },
    coingeckoId: 'bittensor',
  },
  {
    id: 'calamari-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'KMA',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/calamari.svg',
    existentialDeposit: '100000000000',
    chain: {
      id: 'calamari',
    },
    coingeckoId: 'calamari-network',
  },
  {
    id: 'centrifuge-polkadot-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'CFG',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/centrifuge-polkadot.svg',
    existentialDeposit: '1000000000000',
    chain: {
      id: 'centrifuge-polkadot',
    },
    coingeckoId: 'centrifuge',
  },
  {
    id: 'cere-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'CERE',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '10000000000',
    chain: {
      id: 'cere',
    },
  },
  {
    id: 'cess-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'TCESS',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1000000000000000000',
    chain: {
      id: 'cess-testnet',
    },
  },
  {
    id: 'chainx-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'PCX',
    decimals: 8,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/chainx.webp',
    existentialDeposit: '0',
    chain: {
      id: 'chainx',
    },
    coingeckoId: 'chainx',
  },
  {
    id: 'clover-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'CLV',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/clover.svg',
    existentialDeposit: '0',
    chain: {
      id: 'clover',
    },
    coingeckoId: 'clover-finance',
  },
  {
    id: 'composable-finance-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'LAYR',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/composable-finance.svg',
    existentialDeposit: '100000000000',
    chain: {
      id: 'composable-finance',
    },
    coingeckoId: 'composable-finance-layr',
  },
  {
    id: 'composable-finance-substrate-tokens-aseed',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'aSEED',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/aseed.svg',
    existentialDeposit: '0',
    onChainId: 129,
    chain: {
      id: 'composable-finance',
    },
    coingeckoId: 'ausd-seed-acala',
  },
  {
    id: 'composable-finance-substrate-tokens-bnc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'BNC',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/bnc.svg',
    existentialDeposit: '0',
    onChainId: 102,
    chain: {
      id: 'composable-finance',
    },
    coingeckoId: 'bifrost-native-coin',
  },
  {
    id: 'composable-finance-substrate-tokens-dot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'DOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/dot.svg',
    existentialDeposit: '0',
    onChainId: 6,
    chain: {
      id: 'composable-finance',
    },
    coingeckoId: 'polkadot',
  },
  {
    id: 'composable-finance-substrate-tokens-kar',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'KAR',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/kar.svg',
    existentialDeposit: '0',
    onChainId: 101,
    chain: {
      id: 'composable-finance',
    },
    coingeckoId: 'karura',
  },
  {
    id: 'composable-finance-substrate-tokens-ksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'KSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ksm.svg',
    existentialDeposit: '0',
    onChainId: 4,
    chain: {
      id: 'composable-finance',
    },
    coingeckoId: 'kusama',
  },
  {
    id: 'composable-finance-substrate-tokens-movr',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'MOVR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/moonriver.webp',
    existentialDeposit: '0',
    onChainId: 104,
    chain: {
      id: 'composable-finance',
    },
    coingeckoId: 'moonriver',
  },
  {
    id: 'composable-finance-substrate-tokens-usdc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDC',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/usd-coin.webp',
    existentialDeposit: '0',
    onChainId: 131,
    chain: {
      id: 'composable-finance',
    },
    coingeckoId: 'usd-coin',
  },
  {
    id: 'composable-finance-substrate-tokens-usdt',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDT',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/usdt.svg',
    existentialDeposit: '0',
    onChainId: 130,
    chain: {
      id: 'composable-finance',
    },
    coingeckoId: 'tether',
  },
  {
    id: 'composable-finance-substrate-tokens-vksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'vKSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/vksm.svg',
    existentialDeposit: '0',
    onChainId: 103,
    chain: {
      id: 'composable-finance',
    },
  },
  {
    id: 'composable-finance-substrate-tokens-wbtc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'wBTC',
    decimals: 8,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/bitcoin.webp',
    existentialDeposit: '0',
    onChainId: 132,
    chain: {
      id: 'composable-finance',
    },
    coingeckoId: 'bitcoin',
  },
  {
    id: 'composable-finance-substrate-tokens-weth',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'wETH',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/ethereum.webp',
    existentialDeposit: '0',
    onChainId: 133,
    chain: {
      id: 'composable-finance',
    },
    coingeckoId: 'ethereum',
  },
  {
    id: 'crab-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'CRAB',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/crab.svg',
    existentialDeposit: '0',
    chain: {
      id: 'crab',
    },
    coingeckoId: 'darwinia-crab-network',
  },
  {
    id: 'creditcoin-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'CTC',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/creditcoin.svg',
    existentialDeposit: '500',
    chain: {
      id: 'creditcoin',
    },
  },
  {
    id: 'creditcoin-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'CTC',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '500',
    chain: {
      id: 'creditcoin-testnet',
    },
  },
  {
    id: 'crown-sterling-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'CSOV',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    chain: {
      id: 'crown-sterling',
    },
  },
  {
    id: 'crust-parachain-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'CRU',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/crust-parachain.svg',
    existentialDeposit: '100000000',
    chain: {
      id: 'crust-parachain',
    },
    coingeckoId: 'crust-network',
  },
  {
    id: 'crust-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'CRU',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/crust.svg',
    existentialDeposit: '0',
    chain: {
      id: 'crust',
    },
    coingeckoId: 'crust-network',
  },
  {
    id: 'dancebox-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'DANCE',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/dancebox.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'dancebox',
    },
  },
  {
    id: 'darwinia-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'RING',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/darwinia.svg',
    existentialDeposit: '0',
    chain: {
      id: 'darwinia',
    },
    coingeckoId: 'darwinia-network-native-token',
  },
  {
    id: 'dock-pos-mainnet-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'DOCK',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/dock.webp',
    existentialDeposit: '500',
    chain: {
      id: 'dock-pos-mainnet',
    },
    coingeckoId: 'dock',
  },
  {
    id: 'edgeware-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'EDG',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/edgeware.svg',
    existentialDeposit: '10000000000000',
    chain: {
      id: 'edgeware',
    },
    coingeckoId: 'edgeware',
  },
  {
    id: 'encointer-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'KSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/encointer.svg',
    existentialDeposit: '33333333',
    chain: {
      id: 'encointer',
    },
    coingeckoId: 'kusama',
  },
  {
    id: 'encointer-testnet-rococo-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'ROC',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/encointer-testnet-rococo.svg',
    existentialDeposit: '33333333',
    chain: {
      id: 'encointer-testnet-rococo',
    },
  },
  {
    id: 'encointer-testnet-standalone-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'ERT',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/encointer-testnet-standalone.svg',
    existentialDeposit: '500',
    chain: {
      id: 'encointer-testnet-standalone',
    },
  },
  {
    id: 'enjin-matrixchain-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'ENJ',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/enjin-matrixchain.svg',
    existentialDeposit: '100000000000000000',
    chain: {
      id: 'enjin-matrixchain',
    },
  },
  {
    id: 'enjin-matrixchain-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'cENJ',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/enjin-matrixchain-testnet.svg',
    existentialDeposit: '100000000000000000',
    chain: {
      id: 'enjin-matrixchain-testnet',
    },
  },
  {
    id: 'enjin-relay-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'ENJ',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/enjin-relay.svg',
    existentialDeposit: '100000000000000000',
    chain: {
      id: 'enjin-relay',
    },
  },
  {
    id: 'enjin-relay-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'cENJ',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/enjin-relay-testnet.svg',
    existentialDeposit: '100000000000000000',
    chain: {
      id: 'enjin-relay-testnet',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-aca',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'ACA',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/acala.webp',
    existentialDeposit: '0',
    assetId: '6382433',
    chain: {
      id: 'equilibrium-polkadot',
    },
    coingeckoId: 'acala',
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-astr',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'ASTR',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/astar.webp',
    existentialDeposit: '0',
    assetId: '1634956402',
    chain: {
      id: 'equilibrium-polkadot',
    },
    coingeckoId: 'astar',
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-bnb',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'BNB',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/binancecoin.webp',
    existentialDeposit: '0',
    assetId: '6450786',
    chain: {
      id: 'equilibrium-polkadot',
    },
    coingeckoId: 'binancecoin',
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-bnc',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'BNC',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/bnc.svg',
    existentialDeposit: '0',
    assetId: '6450787',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-busd',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'BUSD',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/binance-usd.webp',
    existentialDeposit: '0',
    assetId: '1651864420',
    chain: {
      id: 'equilibrium-polkadot',
    },
    coingeckoId: 'binance-usd',
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-cd613',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'CD613',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '426883035443',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-cd714',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'CD714',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '426883100980',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-cd815',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'CD815',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '426883166517',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-cru',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'CRU',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/crust-network.webp',
    existentialDeposit: '0',
    assetId: '6517365',
    chain: {
      id: 'equilibrium-polkadot',
    },
    coingeckoId: 'crust-network',
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-dot',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'DOT',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/polkadot.webp',
    existentialDeposit: '0',
    assetId: '6582132',
    chain: {
      id: 'equilibrium-polkadot',
    },
    coingeckoId: 'polkadot',
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-eq',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'EQ',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/equilibrium-token.webp',
    existentialDeposit: '0',
    assetId: '25969',
    chain: {
      id: 'equilibrium-polkadot',
    },
    coingeckoId: 'equilibrium-token',
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-eqd',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'EQD',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/eqd.svg',
    existentialDeposit: '0',
    assetId: '6648164',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-eqdot',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'EQDOT',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/eqdot.svg',
    existentialDeposit: '0',
    assetId: '435694104436',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-gens',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'GENS',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '1734700659',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-glmr',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'GLMR',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/moonbeam.webp',
    existentialDeposit: '0',
    assetId: '1735159154',
    chain: {
      id: 'equilibrium-polkadot',
    },
    coingeckoId: 'moonbeam',
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-ibtc',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'iBTC',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ibtc.svg',
    existentialDeposit: '0',
    assetId: '1768060003',
    chain: {
      id: 'equilibrium-polkadot',
    },
    coingeckoId: 'interbtc',
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-intr',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'INTR',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/interlay.webp',
    existentialDeposit: '0',
    assetId: '1768846450',
    chain: {
      id: 'equilibrium-polkadot',
    },
    coingeckoId: 'interlay',
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-ldot',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'LDOT',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ldot.svg',
    existentialDeposit: '0',
    assetId: '1818521460',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-lit',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'LIT',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '7104884',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-lpt0',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'LPT0',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '1819309104',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-lpt1',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'LPT1',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '1819309105',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-mxeth',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'MXETH',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '470171350120',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-mxusdc',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'MXUSDC',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '120364133999715',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-mxwbtc',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'MXWBTC',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '120364166444131',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-para',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'PARA',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/parallel-finance.webp',
    existentialDeposit: '0',
    assetId: '1885434465',
    chain: {
      id: 'equilibrium-polkadot',
    },
    coingeckoId: 'parallel-finance',
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-pen',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'PEN',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '7365998',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-pha',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'PHA',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '7366753',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-q',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'Q',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '113',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-sdot',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'SDOT',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '1935961972',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-tdot',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'TDOT',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/tdot.svg',
    existentialDeposit: '0',
    assetId: '1952739188',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-usdt',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDT',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/tether.webp',
    existentialDeposit: '0',
    assetId: '1970496628',
    chain: {
      id: 'equilibrium-polkadot',
    },
    coingeckoId: 'tether',
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-vdot',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'VDOT',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/vdot.svg',
    existentialDeposit: '0',
    assetId: '1986293620',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-xdot',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'XDOT',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/equilibrium-polkadot-substrate-equilibrium-xdot.svg',
    existentialDeposit: '0',
    assetId: '2019848052',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-xdot2',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'XDOT2',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/equilibrium-polkadot-substrate-equilibrium-xdot2.svg',
    existentialDeposit: '0',
    assetId: '517081101362',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'equilibrium-polkadot-substrate-equilibrium-xdot3',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'XDOT3',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/equilibrium-polkadot-substrate-equilibrium-xdot3.svg',
    existentialDeposit: '0',
    assetId: '517081101363',
    chain: {
      id: 'equilibrium-polkadot',
    },
  },
  {
    id: 'ewx-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'EWT',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    chain: {
      id: 'ewx',
    },
  },
  {
    id: 'frequency-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'FRQCY',
    decimals: 8,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1000000',
    chain: {
      id: 'frequency',
    },
  },
  {
    id: 'genshiro-kusama-substrate-equilibrium-bnb',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'BNB',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '6450786',
    chain: {
      id: 'genshiro-kusama',
    },
  },
  {
    id: 'genshiro-kusama-substrate-equilibrium-busd',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'BUSD',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '1651864420',
    chain: {
      id: 'genshiro-kusama',
    },
  },
  {
    id: 'genshiro-kusama-substrate-equilibrium-crv',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'CRV',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '6517366',
    chain: {
      id: 'genshiro-kusama',
    },
  },
  {
    id: 'genshiro-kusama-substrate-equilibrium-dai',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'DAI',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '6578537',
    chain: {
      id: 'genshiro-kusama',
    },
  },
  {
    id: 'genshiro-kusama-substrate-equilibrium-dot',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'DOT',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/dot.svg',
    existentialDeposit: '0',
    assetId: '6582132',
    chain: {
      id: 'genshiro-kusama',
    },
  },
  {
    id: 'genshiro-kusama-substrate-equilibrium-eqd',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'EQD',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/eqd.svg',
    existentialDeposit: '0',
    assetId: '6648164',
    chain: {
      id: 'genshiro-kusama',
    },
  },
  {
    id: 'genshiro-kusama-substrate-equilibrium-eth',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'ETH',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/eth.svg',
    existentialDeposit: '0',
    assetId: '6648936',
    chain: {
      id: 'genshiro-kusama',
    },
  },
  {
    id: 'genshiro-kusama-substrate-equilibrium-gens',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'GENS',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '1734700659',
    chain: {
      id: 'genshiro-kusama',
    },
  },
  {
    id: 'genshiro-kusama-substrate-equilibrium-hdot',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'HDOT',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '1751412596',
    chain: {
      id: 'genshiro-kusama',
    },
  },
  {
    id: 'genshiro-kusama-substrate-equilibrium-hko',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'HKO',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '6843247',
    chain: {
      id: 'genshiro-kusama',
    },
  },
  {
    id: 'genshiro-kusama-substrate-equilibrium-kar',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'KAR',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/kar.svg',
    existentialDeposit: '0',
    assetId: '7037298',
    chain: {
      id: 'genshiro-kusama',
    },
  },
  {
    id: 'genshiro-kusama-substrate-equilibrium-ksm',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'KSM',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ksm.svg',
    existentialDeposit: '0',
    assetId: '7041901',
    chain: {
      id: 'genshiro-kusama',
    },
  },
  {
    id: 'genshiro-kusama-substrate-equilibrium-lpt0',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'LPT0',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '1819309104',
    chain: {
      id: 'genshiro-kusama',
    },
  },
  {
    id: 'genshiro-kusama-substrate-equilibrium-usdc',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDC',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '1970496611',
    chain: {
      id: 'genshiro-kusama',
    },
  },
  {
    id: 'genshiro-kusama-substrate-equilibrium-usdt',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDT',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/usdt.svg',
    existentialDeposit: '0',
    assetId: '1970496628',
    chain: {
      id: 'genshiro-kusama',
    },
  },
  {
    id: 'genshiro-kusama-substrate-equilibrium-wbtc',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'WBTC',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '2002941027',
    chain: {
      id: 'genshiro-kusama',
    },
  },
  {
    id: 'genshiro-kusama-substrate-equilibrium-xdot',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'XDOT',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '2019848052',
    chain: {
      id: 'genshiro-kusama',
    },
  },
  {
    id: 'genshiro-kusama-substrate-equilibrium-xdot2',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'XDOT2',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '517081101362',
    chain: {
      id: 'genshiro-kusama',
    },
  },
  {
    id: 'genshiro-kusama-substrate-equilibrium-xdot3',
    type: 'substrate-equilibrium',
    isTestnet: false,
    isDefault: true,
    symbol: 'XDOT3',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    assetId: '517081101363',
    chain: {
      id: 'genshiro-kusama',
    },
  },
  {
    id: 'gm-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'FREN',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/gm.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'gm',
    },
  },
  {
    id: 'gm-substrate-tokens-gm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'GM',
    decimals: 0,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/gm.svg',
    existentialDeposit: '0',
    onChainId: 1,
    chain: {
      id: 'gm',
    },
  },
  {
    id: 'gm-substrate-tokens-gn',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'GN',
    decimals: 0,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/gn.svg',
    existentialDeposit: '0',
    onChainId: 2,
    chain: {
      id: 'gm',
    },
  },
  {
    id: 'hashed-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'HASH',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/hashed.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'hashed',
    },
  },
  {
    id: 'heiko-kusama-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'HKO',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/heiko-kusama.svg',
    existentialDeposit: '10000000000',
    chain: {
      id: 'heiko-kusama',
    },
  },
  {
    id: 'humanode-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'HMND',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '500',
    chain: {
      id: 'humanode',
    },
  },
  {
    id: 'hydradx-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'HDX',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/hydradx.svg',
    existentialDeposit: '1000000000000',
    chain: {
      id: 'hydradx',
    },
    coingeckoId: 'hydradx',
  },
  {
    id: 'hydradx-substrate-tokens-ape',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'APE',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/apecoin.webp',
    existentialDeposit: '2518891687657430',
    onChainId: 6,
    chain: {
      id: 'hydradx',
    },
    coingeckoId: 'apecoin',
  },
  {
    id: 'hydradx-substrate-tokens-astr',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'ASTR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/astar.webp',
    existentialDeposit: '147058823529412000',
    onChainId: 9,
    chain: {
      id: 'hydradx',
    },
    coingeckoId: 'astar',
  },
  {
    id: 'hydradx-substrate-tokens-bnc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'BNC',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/bnc.svg',
    existentialDeposit: '68795189840',
    onChainId: 14,
    chain: {
      id: 'hydradx',
    },
    coingeckoId: 'bifrost-native-coin',
  },
  {
    id: 'hydradx-substrate-tokens-cfg',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'CFG',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/centrifuge.webp',
    existentialDeposit: '32467532467532500',
    onChainId: 13,
    chain: {
      id: 'hydradx',
    },
    coingeckoId: 'centrifuge',
  },
  {
    id: 'hydradx-substrate-tokens-dai',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'DAI',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/dai.webp',
    existentialDeposit: '10000000000000000',
    onChainId: 2,
    chain: {
      id: 'hydradx',
    },
    coingeckoId: 'dai',
  },
  {
    id: 'hydradx-substrate-tokens-dot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'DOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/dot.svg',
    existentialDeposit: '17540000',
    onChainId: 5,
    chain: {
      id: 'hydradx',
    },
    coingeckoId: 'polkadot',
  },
  {
    id: 'hydradx-substrate-tokens-ibtc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'iBTC',
    decimals: 8,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ibtc.svg',
    existentialDeposit: '36',
    onChainId: 11,
    chain: {
      id: 'hydradx',
    },
    coingeckoId: 'interbtc',
  },
  {
    id: 'hydradx-substrate-tokens-lrna',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'LRNA',
    decimals: 12,
    existentialDeposit: '400000000',
    onChainId: 1,
    chain: {
      id: 'hydradx',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'hydradx-substrate-tokens-pha',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'PHA',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/pha.webp',
    existentialDeposit: '54945054945',
    onChainId: 8,
    chain: {
      id: 'hydradx',
    },
    coingeckoId: 'pha',
  },
  {
    id: 'hydradx-substrate-tokens-usdc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDC',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/usd-coin.webp',
    existentialDeposit: '10000',
    onChainId: 7,
    chain: {
      id: 'hydradx',
    },
    coingeckoId: 'usd-coin',
  },
  {
    id: 'hydradx-substrate-tokens-usdt',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDT',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/usdt.svg',
    existentialDeposit: '10000',
    onChainId: 10,
    chain: {
      id: 'hydradx',
    },
    coingeckoId: 'tether',
  },
  {
    id: 'hydradx-substrate-tokens-vdot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'vDOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/vdot.svg',
    existentialDeposit: '18761726',
    onChainId: 15,
    chain: {
      id: 'hydradx',
    },
    coingeckoId: 'voucher-dot',
  },
  {
    id: 'hydradx-substrate-tokens-wbtc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'WBTC',
    decimals: 8,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/bitcoin.webp',
    existentialDeposit: '44',
    onChainId: 3,
    chain: {
      id: 'hydradx',
    },
    coingeckoId: 'bitcoin',
  },
  {
    id: 'hydradx-substrate-tokens-weth',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'WETH',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/ethereum.webp',
    existentialDeposit: '7000000000000',
    onChainId: 4,
    chain: {
      id: 'hydradx',
    },
    coingeckoId: 'ethereum',
  },
  {
    id: 'hydradx-substrate-tokens-ztg',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'ZTG',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/zeitgeist.webp',
    existentialDeposit: '1204151916',
    onChainId: 12,
    chain: {
      id: 'hydradx',
    },
    coingeckoId: 'zeitgeist',
  },
  {
    id: 'imbue-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'IMBU',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/imbue.svg',
    existentialDeposit: '1000000',
    chain: {
      id: 'imbue',
    },
    coingeckoId: 'imbue-network',
  },
  {
    id: 'integritee-kusama-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'TEER',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/integritee-kusama.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'integritee-kusama',
    },
    coingeckoId: 'integritee',
  },
  {
    id: 'interlay-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'INTR',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/interlay.svg',
    existentialDeposit: '0',
    chain: {
      id: 'interlay',
    },
    coingeckoId: 'interlay',
  },
  {
    id: 'interlay-substrate-tokens-dot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'DOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/dot.svg',
    existentialDeposit: '0',
    onChainId: '{"Token":"DOT"}',
    chain: {
      id: 'interlay',
    },
    coingeckoId: 'polkadot',
  },
  {
    id: 'interlay-substrate-tokens-ibtc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'iBTC',
    decimals: 8,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ibtc.svg',
    existentialDeposit: '0',
    onChainId: '{"Token":"IBTC"}',
    chain: {
      id: 'interlay',
    },
    coingeckoId: 'interbtc',
  },
  {
    id: 'interlay-substrate-tokens-intr',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'INTR',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/intr.svg',
    existentialDeposit: '0',
    onChainId: '{"Token":"INTR"}',
    chain: {
      id: 'interlay',
    },
    coingeckoId: 'interlay',
  },
  {
    id: 'interlay-substrate-tokens-kbtc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'kBTC',
    decimals: 8,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/kbtc.svg',
    existentialDeposit: '0',
    onChainId: '{"Token":"KBTC"}',
    chain: {
      id: 'interlay',
    },
    coingeckoId: 'kintsugi-btc',
  },
  {
    id: 'interlay-substrate-tokens-kint',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'KINT',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/kint.svg',
    existentialDeposit: '0',
    onChainId: '{"Token":"KINT"}',
    chain: {
      id: 'interlay',
    },
    coingeckoId: 'kintsugi',
  },
  {
    id: 'interlay-substrate-tokens-ksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'KSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ksm.svg',
    existentialDeposit: '0',
    onChainId: '{"Token":"KSM"}',
    chain: {
      id: 'interlay',
    },
    coingeckoId: 'kusama',
  },
  {
    id: 'interlay-substrate-tokens-ldot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'LDOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ldot.svg',
    existentialDeposit: '0',
    onChainId: '{"ForeignAsset":1}',
    chain: {
      id: 'interlay',
    },
    coingeckoId: 'liquid-staking-dot',
  },
  {
    id: 'interlay-substrate-tokens-lp-dot-intr',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp DOT-INTR',
    decimals: 18,
    existentialDeposit: '0',
    onChainId: '{"LpToken":[{"Token":"DOT"},{"Token":"INTR"}]}',
    chain: {
      id: 'interlay',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'interlay-substrate-tokens-lp-ibtc-dot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp iBTC-DOT',
    decimals: 18,
    existentialDeposit: '0',
    onChainId: '{"LpToken":[{"Token":"IBTC"},{"Token":"DOT"}]}',
    chain: {
      id: 'interlay',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'interlay-substrate-tokens-lp-ibtc-usdt',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp iBTC-USDT',
    decimals: 18,
    existentialDeposit: '0',
    onChainId: '{"LpToken":[{"Token":"IBTC"},{"ForeignAsset":2}]}',
    chain: {
      id: 'interlay',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'interlay-substrate-tokens-qdot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'qDOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/qdot.svg',
    existentialDeposit: '0',
    onChainId: '{"LendToken":2}',
    chain: {
      id: 'interlay',
    },
  },
  {
    id: 'interlay-substrate-tokens-qibtc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'qIBTC',
    decimals: 8,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/qibtc.svg',
    existentialDeposit: '0',
    onChainId: '{"LendToken":1}',
    chain: {
      id: 'interlay',
    },
  },
  {
    id: 'interlay-substrate-tokens-qusdt',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'qUSDT',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/qusdt.svg',
    existentialDeposit: '0',
    onChainId: '{"LendToken":3}',
    chain: {
      id: 'interlay',
    },
  },
  {
    id: 'interlay-substrate-tokens-usdt',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDT',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/usdt.svg',
    existentialDeposit: '0',
    onChainId: '{"ForeignAsset":2}',
    chain: {
      id: 'interlay',
    },
    coingeckoId: 'tether',
  },
  {
    id: 'interlay-testnet-standalone-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'INTR',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/intr.svg',
    existentialDeposit: '0',
    chain: {
      id: 'interlay-testnet-standalone',
    },
  },
  {
    id: 'invarch-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'VARCH',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/invarch.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'invarch',
    },
  },
  {
    id: 'ipci-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'MITO',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1000000',
    chain: {
      id: 'ipci',
    },
  },
  {
    id: 'joystream-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'JOY',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/joystream.svg',
    existentialDeposit: '266666560',
    chain: {
      id: 'joystream',
    },
    coingeckoId: 'joystream',
  },
  {
    id: 'jur-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'JUR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/jur.svg',
    existentialDeposit: '500',
    chain: {
      id: 'jur',
    },
  },
  {
    id: 'jur-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'JUR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '500',
    chain: {
      id: 'jur-testnet',
    },
  },
  {
    id: 'kabocha-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'KAB',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'kabocha',
    },
  },
  {
    id: 'karura-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'KAR',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/karura.svg',
    existentialDeposit: '100000000000',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'karura',
  },
  {
    id: 'karura-substrate-tokens-3usd',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: '3USD',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/3usd.svg',
    existentialDeposit: '10000000000',
    onChainId: '{"StableAssetPoolToken":1}',
    chain: {
      id: 'karura',
    },
  },
  {
    id: 'karura-substrate-tokens-air',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'AIR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/altair.webp',
    existentialDeposit: '100000000000000000',
    onChainId: '{"ForeignAsset":12}',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'altair',
  },
  {
    id: 'karura-substrate-tokens-aris',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'ARIS',
    decimals: 8,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/aris.svg',
    existentialDeposit: '1000000000000',
    onChainId: '{"ForeignAsset":1}',
    chain: {
      id: 'karura',
    },
  },
  {
    id: 'karura-substrate-tokens-aseed',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'aSEED',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/aseed.svg',
    existentialDeposit: '10000000000',
    onChainId: '{"Token":"KUSD"}',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'ausd-seed-karura',
  },
  {
    id: 'karura-substrate-tokens-bnc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'BNC',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/bnc.svg',
    existentialDeposit: '8000000000',
    onChainId: '{"Token":"BNC"}',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'bifrost-native-coin',
  },
  {
    id: 'karura-substrate-tokens-bsx',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'BSX',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/basilisk.webp',
    existentialDeposit: '1000000000000',
    onChainId: '{"ForeignAsset":11}',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'basilisk',
  },
  {
    id: 'karura-substrate-tokens-crab',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'CRAB',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/crab.svg',
    existentialDeposit: '1000000000000000000',
    onChainId: '{"ForeignAsset":13}',
    chain: {
      id: 'karura',
    },
  },
  {
    id: 'karura-substrate-tokens-csm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'CSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/crust-storage-market.webp',
    existentialDeposit: '1000000000000',
    onChainId: '{"ForeignAsset":5}',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'crust-storage-market',
  },
  {
    id: 'karura-substrate-tokens-dai',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'DAI',
    decimals: 18,
    existentialDeposit: '10000000000000000',
    onChainId: '{"Erc20":"0x4bb6afb5fa2b07a5d1c499e1c3ddb5a15e709a71"}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-eqd',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'EQD',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/eqd.svg',
    existentialDeposit: '10000000000',
    onChainId: '{"ForeignAsset":15}',
    chain: {
      id: 'karura',
    },
  },
  {
    id: 'karura-substrate-tokens-gens',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'GENS',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/genshiro.webp',
    existentialDeposit: '1000000000000',
    onChainId: '{"ForeignAsset":14}',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'genshiro',
  },
  {
    id: 'karura-substrate-tokens-hko',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'HKO',
    decimals: 12,
    existentialDeposit: '100000000000',
    onChainId: '{"ForeignAsset":4}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-kbtc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'kBTC',
    decimals: 8,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/kbtc.svg',
    existentialDeposit: '66',
    onChainId: '{"Token":"KBTC"}',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'kintsugi-btc',
  },
  {
    id: 'karura-substrate-tokens-kico',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'KICO',
    decimals: 14,
    existentialDeposit: '100000000000000',
    onChainId: '{"ForeignAsset":6}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-kint',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'KINT',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/kint.svg',
    existentialDeposit: '133330000',
    onChainId: '{"Token":"KINT"}',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'kintsugi',
  },
  {
    id: 'karura-substrate-tokens-kma',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'KMA',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/calamari-network.webp',
    existentialDeposit: '100000000000',
    onChainId: '{"ForeignAsset":10}',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'calamari-network',
  },
  {
    id: 'karura-substrate-tokens-ksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'KSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ksm.svg',
    existentialDeposit: '100000000',
    onChainId: '{"Token":"KSM"}',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'kusama',
  },
  {
    id: 'karura-substrate-tokens-lit',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'LIT',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/litentry.webp',
    existentialDeposit: '100000000000',
    onChainId: '{"ForeignAsset":20}',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'litentry',
  },
  {
    id: 'karura-substrate-tokens-lksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'LKSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/liquid-ksm.webp',
    existentialDeposit: '500000000',
    onChainId: '{"Token":"LKSM"}',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'liquid-ksm',
  },
  {
    id: 'karura-substrate-tokens-lp-kar-ksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp KAR-KSM',
    decimals: 12,
    existentialDeposit: '100000000000',
    onChainId: '{"DexShare":[{"Token":"KAR"},{"Token":"KSM"}]}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-lp-kar-kusd',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp KAR-KUSD',
    decimals: 12,
    existentialDeposit: '100000000000',
    onChainId: '{"DexShare":[{"Token":"KAR"},{"Token":"KUSD"}]}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-lp-kar-lksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp KAR-LKSM',
    decimals: 12,
    existentialDeposit: '100000000000',
    onChainId: '{"DexShare":[{"Token":"KAR"},{"Token":"LKSM"}]}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-lp-kar-qtz',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp KAR-QTZ',
    decimals: 12,
    existentialDeposit: '100000000000',
    onChainId: '{"DexShare":[{"Token":"KAR"},{"ForeignAsset":2}]}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-lp-ksm-aris',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp KSM-ARIS',
    decimals: 12,
    existentialDeposit: '100000000',
    onChainId: '{"DexShare":[{"Token":"KSM"},{"ForeignAsset":1}]}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-lp-ksm-lksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp KSM-LKSM',
    decimals: 12,
    existentialDeposit: '100000000',
    onChainId: '{"DexShare":[{"Token":"KSM"},{"Token":"LKSM"}]}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-lp-ksm-rmrk',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp KSM-RMRK',
    decimals: 12,
    existentialDeposit: '100000000',
    onChainId: '{"DexShare":[{"Token":"KSM"},{"ForeignAsset":0}]}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-lp-kusd-air',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp KUSD-AIR',
    decimals: 12,
    existentialDeposit: '10000000000',
    onChainId: '{"DexShare":[{"Token":"KUSD"},{"ForeignAsset":12}]}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-lp-kusd-bnc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp KUSD-BNC',
    decimals: 12,
    existentialDeposit: '10000000000',
    onChainId: '{"DexShare":[{"Token":"KUSD"},{"Token":"BNC"}]}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-lp-kusd-csm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp KUSD-CSM',
    decimals: 12,
    existentialDeposit: '10000000000',
    onChainId: '{"DexShare":[{"Token":"KUSD"},{"ForeignAsset":5}]}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-lp-kusd-kbtc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp KUSD-KBTC',
    decimals: 12,
    existentialDeposit: '10000000000',
    onChainId: '{"DexShare":[{"Token":"KUSD"},{"Token":"KBTC"}]}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-lp-kusd-kint',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp KUSD-KINT',
    decimals: 12,
    existentialDeposit: '10000000000',
    onChainId: '{"DexShare":[{"Token":"KUSD"},{"Token":"KINT"}]}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-lp-kusd-ksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp KUSD-KSM',
    decimals: 12,
    existentialDeposit: '10000000000',
    onChainId: '{"DexShare":[{"Token":"KUSD"},{"Token":"KSM"}]}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-lp-kusd-lksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp KUSD-LKSM',
    decimals: 12,
    existentialDeposit: '10000000000',
    onChainId: '{"DexShare":[{"Token":"KUSD"},{"Token":"LKSM"}]}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-lp-kusd-pha',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp KUSD-PHA',
    decimals: 12,
    existentialDeposit: '10000000000',
    onChainId: '{"DexShare":[{"Token":"KUSD"},{"Token":"PHA"}]}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-lp-kusd-qtz',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp KUSD-QTZ',
    decimals: 12,
    existentialDeposit: '10000000000',
    onChainId: '{"DexShare":[{"Token":"KUSD"},{"ForeignAsset":2}]}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-lp-kusd-rmrk',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp KUSD-RMRK',
    decimals: 12,
    existentialDeposit: '10000000000',
    onChainId: '{"DexShare":[{"Token":"KUSD"},{"ForeignAsset":0}]}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-lp-tai-tksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp TAI-tKSM',
    decimals: 12,
    existentialDeposit: '1000000000000',
    onChainId: '{"DexShare":[{"Token":"TAI"},{"StableAssetPoolToken":0}]}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-lt',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'LT',
    decimals: 12,
    existentialDeposit: '1000000000000',
    onChainId: '{"ForeignAsset":19}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-movr',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'MOVR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/moonriver.webp',
    existentialDeposit: '1000000000000000',
    onChainId: '{"ForeignAsset":3}',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'moonriver',
  },
  {
    id: 'karura-substrate-tokens-neer',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'NEER',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/metaverse-network-pioneer.webp',
    existentialDeposit: '100000000000000000',
    onChainId: '{"ForeignAsset":9}',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'metaverse-network-pioneer',
  },
  {
    id: 'karura-substrate-tokens-pchu',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'PCHU',
    decimals: 18,
    existentialDeposit: '100000000000000000',
    onChainId: '{"ForeignAsset":17}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-pha',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'PHA',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/pha.webp',
    existentialDeposit: '40000000000',
    onChainId: '{"Token":"PHA"}',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'pha',
  },
  {
    id: 'karura-substrate-tokens-qtz',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'QTZ',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/quartz.webp',
    existentialDeposit: '1000000000000000000',
    onChainId: '{"ForeignAsset":2}',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'quartz',
  },
  {
    id: 'karura-substrate-tokens-rmrk',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'RMRK',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/rmrk.svg',
    existentialDeposit: '100000000',
    onChainId: '{"ForeignAsset":0}',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'rmrk',
  },
  {
    id: 'karura-substrate-tokens-sdn',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'SDN',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/shiden.webp',
    existentialDeposit: '10000000000000000',
    onChainId: '{"ForeignAsset":18}',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'shiden',
  },
  {
    id: 'karura-substrate-tokens-tai',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'TAI',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/tai.svg',
    existentialDeposit: '1000000000000',
    onChainId: '{"Token":"TAI"}',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'taiga',
  },
  {
    id: 'karura-substrate-tokens-teer',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'TEER',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/integritee.webp',
    existentialDeposit: '100000000000',
    onChainId: '{"ForeignAsset":8}',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'integritee',
  },
  {
    id: 'karura-substrate-tokens-tksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'tKSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/tksm.svg',
    existentialDeposit: '100000000',
    onChainId: '{"StableAssetPoolToken":0}',
    chain: {
      id: 'karura',
    },
  },
  {
    id: 'karura-substrate-tokens-tur',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'TUR',
    decimals: 10,
    existentialDeposit: '40000000000',
    onChainId: '{"ForeignAsset":16}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-usdcet',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDCet',
    decimals: 6,
    existentialDeposit: '10000',
    onChainId: '{"Erc20":"0x1f3a10587a20114ea25ba1b388ee2dd4a337ce27"}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'karura-substrate-tokens-usdt',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDT',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/usdt.svg',
    existentialDeposit: '10000',
    onChainId: '{"ForeignAsset":7}',
    chain: {
      id: 'karura',
    },
    coingeckoId: 'tether',
  },
  {
    id: 'karura-substrate-tokens-vsksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'VSKSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/vsksm.svg',
    existentialDeposit: '100000000',
    onChainId: '{"Token":"VSKSM"}',
    chain: {
      id: 'karura',
    },
  },
  {
    id: 'karura-substrate-tokens-waseed',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'waSEED',
    decimals: 12,
    existentialDeposit: '100000000000',
    onChainId: '{"Erc20":"0xe20683ad1ed8bbeed7e1ae74be10f19d8045b530"}',
    chain: {
      id: 'karura',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'khala-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'PHA',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/khala.svg',
    existentialDeposit: '10000000000',
    chain: {
      id: 'khala',
    },
    coingeckoId: 'pha',
  },
  {
    id: 'kilt-spiritnet-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'KILT',
    decimals: 15,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/kilt-spiritnet.svg',
    existentialDeposit: '10000000000000',
    chain: {
      id: 'kilt-spiritnet',
    },
    coingeckoId: 'kilt-protocol',
  },
  {
    id: 'kilt-testnet-standalone-2-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'PILT',
    decimals: 15,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/kilt-testnet-standalone-2.svg',
    existentialDeposit: '10000000000000',
    chain: {
      id: 'kilt-testnet-standalone-2',
    },
  },
  {
    id: 'kintsugi-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'KINT',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/kintsugi.svg',
    existentialDeposit: '0',
    chain: {
      id: 'kintsugi',
    },
    coingeckoId: 'kintsugi',
  },
  {
    id: 'kintsugi-substrate-tokens-aseed',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'aSEED',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/aseed.svg',
    existentialDeposit: '0',
    onChainId: '{"ForeignAsset":1}',
    chain: {
      id: 'kintsugi',
    },
    coingeckoId: 'ausd-seed-karura',
  },
  {
    id: 'kintsugi-substrate-tokens-dot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'DOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/dot.svg',
    existentialDeposit: '0',
    onChainId: '{"Token":"DOT"}',
    chain: {
      id: 'kintsugi',
    },
    coingeckoId: 'polkadot',
  },
  {
    id: 'kintsugi-substrate-tokens-ibtc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'iBTC',
    decimals: 8,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ibtc.svg',
    existentialDeposit: '0',
    onChainId: '{"Token":"IBTC"}',
    chain: {
      id: 'kintsugi',
    },
    coingeckoId: 'interbtc',
  },
  {
    id: 'kintsugi-substrate-tokens-intr',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'INTR',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/intr.svg',
    existentialDeposit: '0',
    onChainId: '{"Token":"INTR"}',
    chain: {
      id: 'kintsugi',
    },
    coingeckoId: 'interlay',
  },
  {
    id: 'kintsugi-substrate-tokens-kbtc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'kBTC',
    decimals: 8,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/kbtc.svg',
    existentialDeposit: '0',
    onChainId: '{"Token":"KBTC"}',
    chain: {
      id: 'kintsugi',
    },
    coingeckoId: 'kintsugi-btc',
  },
  {
    id: 'kintsugi-substrate-tokens-kint',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'KINT',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/kint.svg',
    existentialDeposit: '0',
    onChainId: '{"Token":"KINT"}',
    chain: {
      id: 'kintsugi',
    },
    coingeckoId: 'kintsugi',
  },
  {
    id: 'kintsugi-substrate-tokens-ksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'KSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ksm.svg',
    existentialDeposit: '0',
    onChainId: '{"Token":"KSM"}',
    chain: {
      id: 'kintsugi',
    },
    coingeckoId: 'kusama',
  },
  {
    id: 'kintsugi-substrate-tokens-lksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'LKSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/liquid-ksm.webp',
    existentialDeposit: '0',
    onChainId: '{"ForeignAsset":2}',
    chain: {
      id: 'kintsugi',
    },
    coingeckoId: 'liquid-ksm',
  },
  {
    id: 'kintsugi-substrate-tokens-lp-kbtc-ksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp kBTC-KSM',
    decimals: 18,
    existentialDeposit: '0',
    onChainId: '{"LpToken":[{"Token":"KBTC"},{"Token":"KSM"}]}',
    chain: {
      id: 'kintsugi',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'kintsugi-substrate-tokens-lp-kbtc-usdt',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp kBTC-USDT',
    decimals: 18,
    existentialDeposit: '0',
    onChainId: '{"LpToken":[{"Token":"KBTC"},{"ForeignAsset":3}]}',
    chain: {
      id: 'kintsugi',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'kintsugi-substrate-tokens-lp-ksm-kint',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'lp KSM-KINT',
    decimals: 18,
    existentialDeposit: '0',
    onChainId: '{"LpToken":[{"Token":"KSM"},{"Token":"KINT"}]}',
    chain: {
      id: 'kintsugi',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'kintsugi-substrate-tokens-qkbtc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'qKBTC',
    decimals: 8,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/qkbtc.svg',
    existentialDeposit: '0',
    onChainId: '{"LendToken":1}',
    chain: {
      id: 'kintsugi',
    },
  },
  {
    id: 'kintsugi-substrate-tokens-qksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'qKSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/qksm.svg',
    existentialDeposit: '0',
    onChainId: '{"LendToken":2}',
    chain: {
      id: 'kintsugi',
    },
  },
  {
    id: 'kintsugi-substrate-tokens-qusdt',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'qUSDT',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/qusdt.svg',
    existentialDeposit: '0',
    onChainId: '{"LendToken":3}',
    chain: {
      id: 'kintsugi',
    },
  },
  {
    id: 'kintsugi-substrate-tokens-usdt',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDT',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/usdt.svg',
    existentialDeposit: '0',
    onChainId: '{"ForeignAsset":3}',
    chain: {
      id: 'kintsugi',
    },
    coingeckoId: 'tether',
  },
  {
    id: 'kintsugi-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'KINT',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/kint.svg',
    existentialDeposit: '0',
    chain: {
      id: 'kintsugi-testnet',
    },
  },
  {
    id: 'krest-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'KREST',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/krest.svg',
    existentialDeposit: '500',
    chain: {
      id: 'krest',
    },
    coingeckoId: 'krest',
  },
  {
    id: 'kulupu-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'KLP',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    chain: {
      id: 'kulupu',
    },
  },
  {
    id: 'kusama-asset-hub-substrate-assets-16-aris',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'ARIS',
    decimals: 8,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/aris.svg',
    existentialDeposit: '10000000',
    assetId: '16',
    isFrozen: false,
    chain: {
      id: 'kusama-asset-hub',
    },
  },
  {
    id: 'kusama-asset-hub-substrate-assets-1984-usdt',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDT',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/usdt.svg',
    existentialDeposit: '100',
    assetId: '1984',
    isFrozen: false,
    chain: {
      id: 'kusama-asset-hub',
    },
    coingeckoId: 'tether',
  },
  {
    id: 'kusama-asset-hub-substrate-assets-8-rmrk',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'RMRK',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/rmrk.svg',
    existentialDeposit: '10000',
    assetId: '8',
    isFrozen: false,
    chain: {
      id: 'kusama-asset-hub',
    },
    coingeckoId: 'rmrk',
  },
  {
    id: 'kusama-asset-hub-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'KSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/kusama-asset-hub.svg',
    existentialDeposit: '3333333',
    chain: {
      id: 'kusama-asset-hub',
    },
    coingeckoId: 'kusama',
  },
  {
    id: 'kusama-bridge-hub-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'KSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/kusama-bridge-hub.svg',
    existentialDeposit: '33333333',
    chain: {
      id: 'kusama-bridge-hub',
    },
  },
  {
    id: 'kusama-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'KSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/kusama.svg',
    existentialDeposit: '333333333',
    chain: {
      id: 'kusama',
    },
    coingeckoId: 'kusama',
  },
  {
    id: 'litentry-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'LIT',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/litentry.svg',
    existentialDeposit: '100000000000',
    chain: {
      id: 'litentry',
    },
    coingeckoId: 'litentry',
  },
  {
    id: 'litmus-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'LIT',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/litmus.svg',
    existentialDeposit: '100000000000',
    chain: {
      id: 'litmus',
    },
  },
  {
    id: 'logion-standalone-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'LGNT',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '500',
    chain: {
      id: 'logion-standalone',
    },
  },
  {
    id: 'logion-testnet-standalone-2-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'LGNT',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '500',
    chain: {
      id: 'logion-testnet-standalone-2',
    },
  },
  {
    id: 'mangata-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'MGX',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/mangata.svg',
    existentialDeposit: '0',
    chain: {
      id: 'mangata',
    },
  },
  {
    id: 'mangata-substrate-tokens-mgx',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'MGX',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/mangata-x.webp',
    existentialDeposit: '0',
    onChainId: 0,
    chain: {
      id: 'mangata',
    },
    coingeckoId: 'mangata-x',
  },
  {
    id: 'manta-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'MANTA',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/manta.svg',
    existentialDeposit: '100000000000000000',
    chain: {
      id: 'manta',
    },
  },
  {
    id: 'moonbase-alpha-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'DEV',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/moonbase-alpha-testnet.svg',
    existentialDeposit: '0',
    chain: {
      id: 'moonbase-alpha-testnet',
    },
  },
  {
    id: 'moonbeam-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'GLMR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/moonbeam.svg',
    existentialDeposit: '0',
    chain: {
      id: 'moonbeam',
    },
    coingeckoId: 'moonbeam',
  },
  {
    id: 'moonriver-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'MOVR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/moonriver.svg',
    existentialDeposit: '0',
    chain: {
      id: 'moonriver',
    },
    coingeckoId: 'moonriver',
  },
  {
    id: 'moonsama-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'SAMA',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/moonsama.svg',
    existentialDeposit: '1000000000000000',
    chain: {
      id: 'moonsama',
    },
  },
  {
    id: 'neatcoin-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'NEAT',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '10000000000',
    chain: {
      id: 'neatcoin',
    },
  },
  {
    id: 'nftmart-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'NMT',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/nftmart-token.webp',
    existentialDeposit: '0',
    chain: {
      id: 'nftmart',
    },
    coingeckoId: 'nftmart-token',
  },
  {
    id: 'niskala-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'KPGT',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/niskala-testnet.svg',
    existentialDeposit: '500',
    chain: {
      id: 'niskala-testnet',
    },
  },
  {
    id: 'nodle-polkadot-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'NODL',
    decimals: 11,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/nodle-polkadot.svg',
    existentialDeposit: '10000',
    chain: {
      id: 'nodle-polkadot',
    },
    coingeckoId: 'nodle-network',
  },
  {
    id: 'opal-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'OPL',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    chain: {
      id: 'opal-testnet',
    },
  },
  {
    id: 'origintrail-parachain-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'OTP',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/origintrail-parachain.svg',
    existentialDeposit: '1000000000000',
    chain: {
      id: 'origintrail-parachain',
    },
  },
  {
    id: 'parallel-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'PARA',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/parallel.svg',
    existentialDeposit: '100000000000',
    chain: {
      id: 'parallel',
    },
  },
  {
    id: 'parallel-substrate-tokens-usdt',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDT',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/usdt.svg',
    existentialDeposit: '0',
    onChainId: 102,
    chain: {
      id: 'parallel',
    },
    coingeckoId: 'tether',
  },
  {
    id: 'paseo-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'PAS',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/paseo-testnet.svg',
    existentialDeposit: '10000000000',
    chain: {
      id: 'paseo-testnet',
    },
  },
  {
    id: 'pendulum-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'PEN',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/pendulum.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'pendulum',
    },
    coingeckoId: 'pendulum-chain',
  },
  {
    id: 'pendulum-substrate-tokens-dot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'DOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/dot.svg',
    existentialDeposit: '0',
    onChainId: '{"XCM":0}',
    chain: {
      id: 'pendulum',
    },
    coingeckoId: 'polkadot',
  },
  {
    id: 'pendulum-substrate-tokens-usdt',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDT',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/usdt.svg',
    existentialDeposit: '0',
    onChainId: '{"XCM":1}',
    chain: {
      id: 'pendulum',
    },
    coingeckoId: 'tether',
  },
  {
    id: 'phala-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'PHA',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/phala.svg',
    existentialDeposit: '10000000000',
    chain: {
      id: 'phala',
    },
    coingeckoId: 'pha',
  },
  {
    id: 'phala-testnet-standalone-2-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'PHA',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '10000000000',
    chain: {
      id: 'phala-testnet-standalone-2',
    },
  },
  {
    id: 'picasso-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'PICA',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/picasso.svg',
    existentialDeposit: '100000000000',
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'picasso',
  },
  {
    id: 'picasso-substrate-tokens-aseed',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'aSEED',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/aseed.svg',
    existentialDeposit: '0',
    onChainId: 129,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'ausd-seed-karura',
  },
  {
    id: 'picasso-substrate-tokens-astr',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'ASTR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/astar.webp',
    existentialDeposit: '4700000000000000',
    onChainId: 2006,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'astar',
  },
  {
    id: 'picasso-substrate-tokens-atom',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'ATOM',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/atom.svg',
    existentialDeposit: '2500',
    onChainId: 7,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'cosmos',
  },
  {
    id: 'picasso-substrate-tokens-bcre',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'bCRE',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/bcre.svg',
    existentialDeposit: '1000000',
    onChainId: 14,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'liquid-staking-crescent',
  },
  {
    id: 'picasso-substrate-tokens-bld',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'BLD',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/bld.svg',
    existentialDeposit: '120000',
    onChainId: 18,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'agoric',
  },
  {
    id: 'picasso-substrate-tokens-bnc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'BNC',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/bnc.svg',
    existentialDeposit: '0',
    onChainId: 102,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'bifrost-native-coin',
  },
  {
    id: 'picasso-substrate-tokens-bnc-dot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'BNC DOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/bnc dot.svg',
    existentialDeposit: '100000000',
    onChainId: 33,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'bifrost-native-coin',
  },
  {
    id: 'picasso-substrate-tokens-bnc-ksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'BNC KSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/bnc ksm.svg',
    existentialDeposit: '11000000000',
    onChainId: 31,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'bifrost-native-coin',
  },
  {
    id: 'picasso-substrate-tokens-cre',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'CRE',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/cre.svg',
    existentialDeposit: '1000000',
    onChainId: 13,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'crescent-network',
  },
  {
    id: 'picasso-substrate-tokens-dot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'DOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/dot.svg',
    existentialDeposit: '0',
    onChainId: 6,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'polkadot',
  },
  {
    id: 'picasso-substrate-tokens-eq',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'EQ',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/eq.svg',
    existentialDeposit: '700000000',
    onChainId: 2011,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'equilibrium-token',
  },
  {
    id: 'picasso-substrate-tokens-eqd',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'EQD',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/eqd.svg',
    existentialDeposit: '200000',
    onChainId: 127,
    chain: {
      id: 'picasso',
    },
  },
  {
    id: 'picasso-substrate-tokens-huahua',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'HUAHUA',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/huahua.svg',
    existentialDeposit: '1000000',
    onChainId: 420,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'chihuahua-token',
  },
  {
    id: 'picasso-substrate-tokens-kar',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'KAR',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/kar.svg',
    existentialDeposit: '0',
    onChainId: 101,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'karura',
  },
  {
    id: 'picasso-substrate-tokens-ksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'KSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ksm.svg',
    existentialDeposit: '0',
    onChainId: 4,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'kusama',
  },
  {
    id: 'picasso-substrate-tokens-movr',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'MOVR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/moonriver.webp',
    existentialDeposit: '0',
    onChainId: 104,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'moonriver',
  },
  {
    id: 'picasso-substrate-tokens-ntrn',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'NTRN',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/ntrn.svg',
    existentialDeposit: '60000',
    onChainId: 12,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'neutron',
  },
  {
    id: 'picasso-substrate-tokens-osmo',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'OSMO',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/osmo.svg',
    existentialDeposit: '40000',
    onChainId: 8,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'osmosis',
  },
  {
    id: 'picasso-substrate-tokens-pica',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'PICA',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/pica.svg',
    existentialDeposit: '0',
    onChainId: 1,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'picasso',
  },
  {
    id: 'picasso-substrate-tokens-scrt',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'SCRT',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/scrt.svg',
    existentialDeposit: '70000',
    onChainId: 15,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'secret',
  },
  {
    id: 'picasso-substrate-tokens-sdn',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'SDN',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/shiden.webp',
    existentialDeposit: '1000000000000000',
    onChainId: 2007,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'shiden',
  },
  {
    id: 'picasso-substrate-tokens-stars',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'STARS',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/stars.svg',
    existentialDeposit: '2000000',
    onChainId: 11,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'stargaze',
  },
  {
    id: 'picasso-substrate-tokens-statom',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'stATOM',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/statom.svg',
    existentialDeposit: '2000',
    onChainId: 10,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'stride-staked-atom',
  },
  {
    id: 'picasso-substrate-tokens-strd',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'STRD',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/strd.svg',
    existentialDeposit: '20000',
    onChainId: 9,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'stride',
  },
  {
    id: 'picasso-substrate-tokens-tnkr',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'TNKR',
    decimals: 12,
    existentialDeposit: '13500000000',
    onChainId: 212,
    chain: {
      id: 'picasso',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'picasso-substrate-tokens-umee',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'UMEE',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/umee.svg',
    existentialDeposit: '1000000',
    onChainId: 17,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'umee',
  },
  {
    id: 'picasso-substrate-tokens-usdc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDC',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/usd-coin.webp',
    existentialDeposit: '0',
    onChainId: 131,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'usd-coin',
  },
  {
    id: 'picasso-substrate-tokens-usdt',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDT',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/usdt.svg',
    existentialDeposit: '0',
    onChainId: 130,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'tether',
  },
  {
    id: 'picasso-substrate-tokens-vdot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'vDOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/vdot.svg',
    existentialDeposit: '100000000',
    onChainId: 34,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'voucher-dot',
  },
  {
    id: 'picasso-substrate-tokens-vksm',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'vKSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/vksm.svg',
    existentialDeposit: '100000000',
    onChainId: 103,
    chain: {
      id: 'picasso',
    },
  },
  {
    id: 'picasso-substrate-tokens-wbtc',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'wBTC',
    decimals: 8,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/bitcoin.webp',
    existentialDeposit: '0',
    onChainId: 132,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'bitcoin',
  },
  {
    id: 'picasso-substrate-tokens-weth',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'wETH',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/ethereum.webp',
    existentialDeposit: '0',
    onChainId: 133,
    chain: {
      id: 'picasso',
    },
    coingeckoId: 'ethereum',
  },
  {
    id: 'polimec-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'PLMC',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/polimec.svg',
    existentialDeposit: '10000000',
    chain: {
      id: 'polimec',
    },
    coingeckoId: 'polimec',
  },
  {
    id: 'polkadex-polkadot-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'PDEX',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/polkadex-polkadot.svg',
    existentialDeposit: '1000000000000',
    chain: {
      id: 'polkadex-polkadot',
    },
    coingeckoId: 'polkadex',
  },
  {
    id: 'polkadex-standalone-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'PDEX',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/polkadex-standalone.svg',
    existentialDeposit: '1000000000000',
    chain: {
      id: 'polkadex-standalone',
    },
    coingeckoId: 'polkadex',
  },
  {
    id: 'polkadot-asset-hub-substrate-assets-1337-usdc',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDC',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/usd-coin.webp',
    existentialDeposit: '700000',
    assetId: '1337',
    isFrozen: false,
    chain: {
      id: 'polkadot-asset-hub',
    },
    coingeckoId: 'usd-coin',
  },
  {
    id: 'polkadot-asset-hub-substrate-assets-1984-usdt',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'USDT',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/usdt.svg',
    existentialDeposit: '700000',
    assetId: '1984',
    isFrozen: false,
    chain: {
      id: 'polkadot-asset-hub',
    },
    coingeckoId: 'tether',
  },
  {
    id: 'polkadot-asset-hub-substrate-assets-23-pink',
    type: 'substrate-assets',
    isTestnet: false,
    isDefault: true,
    symbol: 'PINK',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/pink.png',
    existentialDeposit: '1',
    assetId: '23',
    isFrozen: false,
    chain: {
      id: 'polkadot-asset-hub',
    },
  },
  {
    id: 'polkadot-asset-hub-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'DOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/polkadot-asset-hub.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'polkadot-asset-hub',
    },
    coingeckoId: 'polkadot',
  },
  {
    id: 'polkadot-bridge-hub-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'DOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/polkadot-bridge-hub.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'polkadot-bridge-hub',
    },
  },
  {
    id: 'polkadot-collectives-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'DOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/polkadot-collectives.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'polkadot-collectives',
    },
  },
  {
    id: 'polkadot-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'DOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/polkadot.svg',
    existentialDeposit: '10000000000',
    chain: {
      id: 'polkadot',
    },
    coingeckoId: 'polkadot',
    dcentName: 'POLKADOT',
  },
  {
    id: 'polymesh-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'POLYX',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/coingecko/polymesh.webp',
    existentialDeposit: '0',
    chain: {
      id: 'polymesh',
    },
    coingeckoId: 'polymesh',
  },
  {
    id: 'polymesh-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'POLYX',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    chain: {
      id: 'polymesh-testnet',
    },
  },
  {
    id: 'qpn-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'qpFRM',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1000000000000000000',
    chain: {
      id: 'qpn',
    },
  },
  {
    id: 'quartz-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'QTZ',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/quartz.svg',
    existentialDeposit: '0',
    chain: {
      id: 'quartz',
    },
    coingeckoId: 'quartz',
  },
  {
    id: 'robonomics-kusama-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'XRT',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/robonomics-kusama.svg',
    existentialDeposit: '1000',
    chain: {
      id: 'robonomics-kusama',
    },
    coingeckoId: 'robonomics-network',
  },
  {
    id: 'rococo-acurast-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'tACU',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'rococo-acurast-testnet',
    },
  },
  {
    id: 'rococo-amplitude-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'AMPE',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'rococo-amplitude-testnet',
    },
  },
  {
    id: 'rococo-asset-hub-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'ROC',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '3333333',
    chain: {
      id: 'rococo-asset-hub-testnet',
    },
  },
  {
    id: 'rococo-aventus-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'AVT',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    chain: {
      id: 'rococo-aventus-testnet',
    },
  },
  {
    id: 'rococo-bajun-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'BAJU',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'rococo-bajun-testnet',
    },
  },
  {
    id: 'rococo-basilisk-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'BSX',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/rococo-basilisk-testnet.svg',
    existentialDeposit: '1000000000000',
    chain: {
      id: 'rococo-basilisk-testnet',
    },
  },
  {
    id: 'rococo-bifrost-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'BNC',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/bnc.svg',
    existentialDeposit: '10000000000',
    chain: {
      id: 'rococo-bifrost-testnet',
    },
  },
  {
    id: 'rococo-bridge-hub-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'ROC',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '3333333',
    chain: {
      id: 'rococo-bridge-hub-testnet',
    },
  },
  {
    id: 'rococo-contracts-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'ROC',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '3333333',
    chain: {
      id: 'rococo-contracts-testnet',
    },
  },
  {
    id: 'rococo-ewx-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'VT',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    chain: {
      id: 'rococo-ewx-testnet',
    },
  },
  {
    id: 'rococo-frequency-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'XRQCY',
    decimals: 8,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1000000',
    chain: {
      id: 'rococo-frequency-testnet',
    },
  },
  {
    id: 'rococo-hydra-dx-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'HDX',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1000000000000',
    chain: {
      id: 'rococo-hydra-dx-testnet',
    },
  },
  {
    id: 'rococo-integritee-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'TEER',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/rococo-integritee-testnet.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'rococo-integritee-testnet',
    },
  },
  {
    id: 'rococo-metaquity-network-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'MQTY',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1000000000000000',
    chain: {
      id: 'rococo-metaquity-network-testnet',
    },
  },
  {
    id: 'rococo-nodle-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'notNODL',
    decimals: 11,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/rococo-nodle-testnet.svg',
    existentialDeposit: '10000',
    chain: {
      id: 'rococo-nodle-testnet',
    },
  },
  {
    id: 'rococo-origin-trail-parachain-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'OTP',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1000000000000',
    chain: {
      id: 'rococo-origin-trail-parachain-testnet',
    },
  },
  {
    id: 'rococo-pangolin-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'PRING',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    chain: {
      id: 'rococo-pangolin-testnet',
    },
  },
  {
    id: 'rococo-phala-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'PHA',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '10000000000',
    chain: {
      id: 'rococo-phala-testnet',
    },
  },
  {
    id: 'rococo-sora-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'XOR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/rococo-sora-testnet.svg',
    existentialDeposit: '1000000000000000',
    chain: {
      id: 'rococo-sora-testnet',
    },
  },
  {
    id: 'rococo-subsocial-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'SOON',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/rococo-subsocial-testnet.svg',
    existentialDeposit: '100000000',
    chain: {
      id: 'rococo-subsocial-testnet',
    },
  },
  {
    id: 'rococo-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'ROC',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/rococo-testnet.svg',
    existentialDeposit: '33333333',
    chain: {
      id: 'rococo-testnet',
    },
  },
  {
    id: 'rococo-trappist-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'HOP',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '3333333',
    chain: {
      id: 'rococo-trappist-testnet',
    },
  },
  {
    id: 'rococo-turing-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'TUR',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '100000000',
    chain: {
      id: 'rococo-turing-testnet',
    },
  },
  {
    id: 'rococo-watr-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'WATRD',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/rococo-watr-testnet.svg',
    existentialDeposit: '10000000000000000',
    chain: {
      id: 'rococo-watr-testnet',
    },
  },
  {
    id: 'rococo-yerba-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'ROC',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '33333333',
    chain: {
      id: 'rococo-yerba-testnet',
    },
  },
  {
    id: 'rococo-zeitgeist-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'ZBS',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/rococo-zeitgeist-testnet.svg',
    existentialDeposit: '50000000',
    chain: {
      id: 'rococo-zeitgeist-testnet',
    },
  },
  {
    id: 'shadow-kusama-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'CSM',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/shadow-kusama.svg',
    existentialDeposit: '100000000000',
    chain: {
      id: 'shadow-kusama',
    },
    coingeckoId: 'crust-storage-market',
  },
  {
    id: 'shibuya-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'SBY',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/shibuya-testnet.svg',
    existentialDeposit: '1000000',
    chain: {
      id: 'shibuya-testnet',
    },
  },
  {
    id: 'shiden-kusama-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'SDN',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/shiden-kusama.svg',
    existentialDeposit: '1000000',
    chain: {
      id: 'shiden-kusama',
    },
    coingeckoId: 'shiden',
  },
  {
    id: 'sora-standalone-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'XOR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/sora-standalone.svg',
    existentialDeposit: '0',
    chain: {
      id: 'sora-standalone',
    },
    coingeckoId: 'sora',
  },
  {
    id: 'sora-substrate-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'XOR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    chain: {
      id: 'sora-substrate-testnet',
    },
  },
  {
    id: 'subsocial-polkadot-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'SUB',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/subsocial-polkadot.svg',
    existentialDeposit: '100000000',
    chain: {
      id: 'subsocial-polkadot',
    },
    coingeckoId: 'subsocial',
  },
  {
    id: 'subspace-gemini-1-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'tSSC',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '500',
    chain: {
      id: 'subspace-gemini-1-testnet',
    },
  },
  {
    id: 'subspace-gemini-2-a-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'tSSC',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '500',
    chain: {
      id: 'subspace-gemini-2-a-testnet',
    },
  },
  {
    id: 'subspace-gemini-3-f-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'tSSC',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '500',
    chain: {
      id: 'subspace-gemini-3-f-testnet',
    },
  },
  {
    id: 'subspace-gemini-3-g-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'tSSC',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '500',
    chain: {
      id: 'subspace-gemini-3-g-testnet',
    },
  },
  {
    id: 't-0-rn-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'T0RN',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1',
    chain: {
      id: 't-0-rn-testnet',
    },
  },
  {
    id: 't-1-rn-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'TIN',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 't-1-rn',
    },
  },
  {
    id: 't-3-rn-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'TRN',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 't-3-rn',
    },
  },
  {
    id: 'tangle-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'tTNT',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1000000000000000000',
    chain: {
      id: 'tangle-testnet',
    },
  },
  {
    id: 'ternoa-alphanet-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'CAPS',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/ternoa-alphanet-testnet.svg',
    existentialDeposit: '150000000000000000',
    chain: {
      id: 'ternoa-alphanet-testnet',
    },
  },
  {
    id: 'ternoa-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'CAPS',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/ternoa.svg',
    existentialDeposit: '150000000000000000',
    chain: {
      id: 'ternoa',
    },
  },
  {
    id: 'thebifrost-mainnet-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'BFC',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    chain: {
      id: 'thebifrost-mainnet',
    },
  },
  {
    id: 'thebifrost-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'BFC',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '0',
    chain: {
      id: 'thebifrost-testnet',
    },
  },
  {
    id: 'tinker-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'TNKR',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/tinker.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'tinker',
    },
  },
  {
    id: 'turing-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'TUR',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/turing.svg',
    existentialDeposit: '100000000',
    chain: {
      id: 'turing',
    },
    coingeckoId: 'turing-network',
  },
  {
    id: 'unique-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'UNQ',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/unique.svg',
    existentialDeposit: '0',
    chain: {
      id: 'unique',
    },
    coingeckoId: 'unique-network',
  },
  {
    id: 'vara-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'VARA',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/vara.svg',
    existentialDeposit: '10000000000000',
    chain: {
      id: 'vara',
    },
    coingeckoId: 'vara-network',
  },
  {
    id: 'watr-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'WATR',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/watr.svg',
    existentialDeposit: '10000000000000000',
    chain: {
      id: 'watr',
    },
  },
  {
    id: 'westend-asset-hub-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'WND',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'westend-asset-hub-testnet',
    },
  },
  {
    id: 'westend-bridge-hub-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'WND',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'westend-bridge-hub-testnet',
    },
  },
  {
    id: 'westend-collectives-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'WND',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1000000000',
    chain: {
      id: 'westend-collectives-testnet',
    },
  },
  {
    id: 'westend-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'WND',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/westend-testnet.svg',
    existentialDeposit: '10000000000',
    chain: {
      id: 'westend-testnet',
    },
  },
  {
    id: 'xsocial-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'SUB',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '100000000',
    chain: {
      id: 'xsocial-testnet',
    },
  },
  {
    id: 'zeitgeist-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'ZTG',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/zeitgeist.svg',
    existentialDeposit: '50000000',
    chain: {
      id: 'zeitgeist',
    },
    coingeckoId: 'zeitgeist',
  },
  {
    id: 'zero-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'ZERO',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/zero.svg',
    existentialDeposit: '10000000000000000',
    chain: {
      id: 'zero',
    },
  },
  {
    id: 'zero-substrate-tokens-dot',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'DOT',
    decimals: 10,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/dot.svg',
    existentialDeposit: '0',
    onChainId: '{"Token":"DOT"}',
    chain: {
      id: 'zero',
    },
    coingeckoId: 'polkadot',
  },
  {
    id: 'zero-substrate-tokens-game',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'GAME',
    decimals: 10,
    existentialDeposit: '0',
    onChainId: '{"Token":"GAME"}',
    chain: {
      id: 'zero',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'zero-substrate-tokens-play',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'PLAY',
    decimals: 10,
    existentialDeposit: '0',
    onChainId: '{"Token":"PLAY"}',
    chain: {
      id: 'zero',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'zero-substrate-tokens-zero',
    type: 'substrate-tokens',
    isTestnet: false,
    isDefault: true,
    symbol: 'ZERO',
    decimals: 18,
    existentialDeposit: '0',
    onChainId: '{"Token":"ZERO"}',
    chain: {
      id: 'zero',
    },
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
  },
  {
    id: 'rococo-neuro-web-testnet-substrate-native',
    type: 'substrate-native',
    isTestnet: true,
    isDefault: true,
    symbol: 'NEURO',
    decimals: 12,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/tokens/unknown.svg',
    existentialDeposit: '1000000000000', // 10**12 Planck
    chain: {
      id: 'rococo-neuro-web-testnet',
    },
  },
  {
    id: 'mythos-substrate-native',
    type: 'substrate-native',
    isTestnet: false,
    isDefault: true,
    symbol: 'MYTH',
    decimals: 18,
    logo: 'https://raw.githubusercontent.com/TalismanSociety/chaindata/main/assets/chains/mythos.svg',
    existentialDeposit: '10000000000000000',
    chain: {
      id: 'mythos',
    },
    coingeckoId: 'mythos',
  },
]

export const ALL_TOKENS_BY_ID = ALL_TOKENS.reduce((a, b) => {
  const chain = supportedChains.find(c => c.id === b.chain.id)
  if (!chain) return a
  a[b.id] = {
    ...b,
    chain,
  }
  return a
}, {} as Record<string, BaseToken>)

export const getTokenById = (id: string) => ALL_TOKENS_BY_ID[id]
