import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { keyframes, useTheme } from '@emotion/react';
import { X } from '@talismn/icons';
import { Button, Dialog, Surface, Text } from '../../atoms';
export const ALERT_DIALOG_PADDING = '2.4rem';
const show = keyframes `
  from {
    opacity: 0;
    transform: translateY(2rem);
  }
`;
const backdropKeyframes = keyframes `
  from {
    background: rgba(0,0,0,0);
    backdrop-filter: blur(0);
  }
  to {
    background: rgba(0,0,0,0.6);
    backdrop-filter: blur(16px);
  }
`;
const AlertDialog = ({ title, content, confirmButton, dismissButton, onRequestDismiss, width, ...props }) => {
    const theme = useTheme();
    return (_jsx(_Fragment, { children: _jsxs(Surface, { as: Dialog, ...props, title: undefined, onClickBackdrop: onRequestDismiss, onClose: onRequestDismiss, onCancel: onRequestDismiss, css: {
                'padding': ALERT_DIALOG_PADDING,
                'background': theme.color.surface,
                'border': 'none',
                'borderRadius': '1.6rem',
                '&[open]': {
                    'animation': `${show} .5s ease`,
                    '::backdrop': {
                        background: 'rgba(0,0,0,0.6)',
                        backdropFilter: 'blur(16px)',
                        animation: `${backdropKeyframes} .5s ease forwards`,
                    },
                },
                'width': 'auto',
                '@media (min-width: 768px)': {
                    width: width ?? 'revert',
                },
            }, children: [_jsxs("header", { css: { display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', marginBottom: '2.6rem' }, children: [_jsx(Text.H4, { css: { marginBottom: 0 }, children: title }), _jsx(Button, { variant: "noop", onClick: onRequestDismiss, children: _jsx(X, { size: "1.6rem" }) })] }), content, (dismissButton || confirmButton) && (_jsxs("div", { css: {
                        'display': 'flex',
                        'gap': '1.6rem',
                        'marginTop': '4.6rem',
                        '> *': {
                            flex: 1,
                        },
                    }, children: [dismissButton, confirmButton] }))] }) }));
};
export default AlertDialog;
