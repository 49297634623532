import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { keyframes, useTheme } from '@emotion/react';
import { X } from '@talismn/icons';
import { IconContext } from '@talismn/icons';
import { createContext, useCallback, useContext, } from 'react';
import { Dialog, IconButton, Text } from '../../atoms';
const backdropKeyframes = keyframes `
  from {
    background: rgba(18,18,18,0);
    backdrop-filter: blur(0);
  }
  to {
    background: rgba(18,18,18,0.9);
    backdrop-filter: blur(32px);
  }
`;
const Context = createContext({});
const NavigationDrawerItem = (props) => {
    const theme = useTheme();
    const context = useContext(Context);
    return (_jsx("button", { onClick: useCallback(() => {
            props.onClick?.();
            context.onRequestDismiss?.();
        }, [context, props]), css: {
            'border': 'none',
            'borderRadius': '2.4rem',
            'boxShadow': '0px 2px 2px rgba(0, 0, 0, 0.25)',
            'width': '100%',
            'padding': '2.4rem 4rem',
            'backgroundColor': `color-mix(in srgb, ${theme.color.foregroundVariant}, transparent 60%)`,
            'cursor': 'pointer',
            ':hover': { filter: 'brightness(1.2)' },
        }, children: _jsxs(Text.BodyLarge, { alpha: "high", css: {
                'display': 'flex',
                'alignItems': 'center',
                'gap': '3.7rem',
                '@media(min-width: 375px)': { fontSize: '2.4rem' },
            }, children: [_jsx(IconContext.Provider, { value: { size: '1em' }, children: props.icon }), props.label] }) }));
};
const NavigationDrawerFooter = Object.assign((props) => (_jsx(Text.Body, { as: "footer", ...props, css: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        gap: '1.8rem',
        flexWrap: 'wrap',
        position: 'sticky',
        top: '100vh',
        padding: '0 4.8rem',
        margin: '3.6rem 0 4rem 0',
    } })), {
    Icon: (props) => (_jsx(Text.Body, { alpha: ({ hover }) => (hover ? 'high' : 'medium'), children: props.children })),
    A: (props) => (_jsx(Text.Body, { alpha: ({ hover }) => (hover ? 'high' : 'medium'), ...props })),
});
const NavigationDrawer = Object.assign((props) => (_jsx(Dialog, { open: props.open, onClickBackdrop: props.onRequestDismiss, css: {
        'width': '100%',
        'maxWidth': '100%',
        'height': '100%',
        'maxHeight': '100%',
        'border': 'none',
        'margin': 0,
        'padding': 0,
        'backgroundColor': 'transparent',
        '&[open]': {
            '::backdrop': {
                background: 'rgba(18,18,18,0.9)',
                backdropFilter: 'blur(32px)',
                animation: `${backdropKeyframes} .5s ease forwards`,
            },
        },
    }, children: _jsxs(Context.Provider, { value: { open: props.open, onRequestDismiss: props.onRequestDismiss }, children: [_jsxs("header", { css: {
                    position: 'sticky',
                    top: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '1.6rem 2.4rem',
                    marginBottom: '1.6rem',
                }, children: [_jsx("div", { children: props.headerIcon }), _jsx(IconButton, { onClick: props.onRequestDismiss, children: _jsx(X, {}) })] }), _jsx("ul", { css: {
                    listStyle: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    gap: '1.2rem',
                    padding: '0 4.8rem',
                }, children: props.children }), props.footer] }) })), { Item: NavigationDrawerItem, Footer: NavigationDrawerFooter });
export default NavigationDrawer;
