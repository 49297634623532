import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTheme } from '@emotion/react';
import { Check, X } from '@talismn/icons';
import { formatDistanceToNowStrict } from 'date-fns';
import { motion } from 'framer-motion';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { resolveValue } from 'react-hot-toast/headless';
import { CircularProgressIndicator, Text } from '../../atoms';
const ToastBar = ({ toast }) => {
    const theme = useTheme();
    const [createdAtDistance, setCreatedAtDistance] = useState(formatDistanceToNowStrict(toast.createdAt, { addSuffix: true }));
    const children = resolveValue(toast.message, toast);
    const resolvedChildren = children?.type === Fragment ? children?.props?.children : children;
    const [firstMessage, ...messages] = React.Children.toArray(resolvedChildren);
    useEffect(() => {
        const interval = setInterval(() => setCreatedAtDistance(formatDistanceToNowStrict(toast.createdAt, { addSuffix: true })), 1000);
        return () => clearInterval(interval);
    }, [toast.createdAt]);
    const origin = useMemo(() => {
        switch (toast.position) {
            case 'top-center':
                return { y: '-100%' };
            case 'bottom-left':
            case 'bottom-center':
            case 'bottom-right':
                return { y: '100%' };
            case 'top-right':
            case undefined:
                return { x: '100%' };
            case 'top-left':
                return { x: '-100%' };
        }
    }, [toast.position]);
    return (_jsxs(motion.div, { css: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            gap: '1.2rem',
            padding: '1.6rem',
            borderRadius: '0.8rem',
            backgroundColor: theme.color.surface,
        }, initial: { ...origin, opacity: 0, scale: 0.8 }, animate: { ...(toast.visible ? { x: 0, y: 0 } : origin), opacity: toast.visible ? 1 : 0, scale: 1 }, whileHover: { scale: 1.01 }, ...toast.ariaProps, children: [useMemo(() => {
                const commonCss = {
                    flexShrink: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '4rem',
                    height: '4rem',
                    borderRadius: '2rem',
                };
                if (toast.icon !== undefined) {
                    return (_jsx("div", { css: {
                            ...commonCss,
                            backgroundColor: toast.iconTheme?.primary,
                            color: toast.iconTheme?.secondary,
                        }, children: resolveValue(toast.icon, toast) }));
                }
                switch (toast.type) {
                    case 'loading':
                        return _jsx(CircularProgressIndicator, { size: "4rem" });
                    case 'success':
                        return (_jsx("div", { css: {
                                ...commonCss,
                                backgroundColor: 'rgba(56, 212, 72, 0.25)',
                                color: '#38D448',
                            }, children: _jsx(Check, { size: "2rem" }) }));
                    case 'error':
                        return (_jsx("div", { css: {
                                ...commonCss,
                                backgroundColor: 'rgba(210, 36, 36, 0.25)',
                                color: '#D22424',
                            }, children: _jsx(X, { size: "2rem" }) }));
                }
                return undefined;
            }, [toast]), _jsxs(Text.Body, { as: "div", children: [_jsxs(Text.Body, { css: { display: 'flex', justifyContent: 'space-between', gap: '1rem' }, children: [_jsx("span", { children: firstMessage }), _jsx(Text.Body, { alpha: "disabled", css: { textAlign: 'end', alignSelf: 'first baseline', minWidth: '11rem' }, children: createdAtDistance })] }), messages] })] }, toast.id));
};
export default ToastBar;
