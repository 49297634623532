import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { FloatingPortal as BaseFloatingPortal } from '@floating-ui/react';
import { useLayoutEffect, useState } from 'react';
const FloatingPortal = (props) => {
    const [element, setElement] = useState(null);
    const [root, setRoot] = useState(document.body);
    useLayoutEffect(() => setRoot(Array.from(document.querySelectorAll('dialog'))
        .filter(x => x.contains(element))
        .at(-1) ?? document.body), [element]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { ref: setElement, css: { display: 'none' } }), _jsx(BaseFloatingPortal, { ...props, root: root })] }));
};
export default FloatingPortal;
