import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { motion } from 'framer-motion';
import { createContext } from 'react';
import { Clickable, Text } from '../../atoms';
const SegmentedButtonContext = createContext({ value: undefined, onChange: () => { } });
const ButtonSegment = (props) => (_jsx(SegmentedButtonContext.Consumer, { children: ({ value: selectedValue, onChange }) => (_jsxs(Text.Body, { as: Clickable, ...props, color: selectedValue === props.value ? theme => theme.color.onPrimary : undefined, css: { position: 'relative', padding: '1rem 1.2rem' }, onClick: () => onChange(props.value), children: [selectedValue === props.value && (_jsx(motion.div, { layoutId: "highlight", css: theme => ({
                    position: 'absolute',
                    inset: 0,
                    borderRadius: '1.2rem',
                    backgroundColor: theme.color.primary,
                }) })), _jsx("div", { css: { position: 'relative' }, children: props.children })] })) }));
const SegmentedButton = Object.assign((props) => (_jsx(SegmentedButtonContext.Provider, { value: {
        value: props.value,
        // @ts-expect-error
        onChange: props.onChange,
    }, children: _jsx("section", { className: props.className, css: theme => ({ borderRadius: '1.4rem', padding: '0.4rem', backgroundColor: theme.color.surface }), children: props.children }) })), { ButtonSegment });
export default SegmentedButton;
