import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Global } from '@emotion/react';
import React, { useEffect, useState } from 'react';
export const Dialog = React.forwardRef(function Dialog({ open = true, isModal = true, keepMounted = false, onClickBackdrop, ...props }, ref) {
    const [element, setElement] = useState(null);
    useEffect(() => {
        if (open) {
            if (element?.open === false) {
                if (isModal) {
                    element.showModal();
                }
                else {
                    element.show();
                }
            }
        }
        else {
            if (element?.open) {
                element.close();
            }
        }
    }, [element, isModal, open]);
    useEffect(() => {
        const listener = function (event) {
            if (event.target !== element) {
                return;
            }
            const rect = this.getBoundingClientRect();
            if (event.clientY < rect.top ||
                event.clientY > rect.bottom ||
                event.clientX < rect.left ||
                event.clientX > rect.right) {
                onClickBackdrop?.();
            }
        };
        element?.addEventListener('click', listener);
        return () => element?.removeEventListener('click', listener);
    }, [element, onClickBackdrop]);
    if (!open && !keepMounted) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [open && _jsx(Global, { styles: { body: { overflow: 'hidden' } } }), _jsx("dialog", { ref: element => {
                    if (typeof ref === 'function') {
                        ref(element);
                    }
                    else if (ref !== null) {
                        ref.current = element;
                    }
                    setElement(element);
                }, ...props })] }));
});
export default Dialog;
