import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { forwardRef } from 'react';
import { IconContext } from '../context';
const SvgAlertCircle = (props, ref) => {
    const iconContext = React.useContext(IconContext);
    return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: props.size ?? iconContext.size ?? 24, height: props.size ?? iconContext.size ?? 24, fill: "none", viewBox: "0 0 24 24", ref: ref, ...props, children: [_jsxs("g", { stroke: "currentcolor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2, clipPath: "url(#a)", children: [_jsx("path", { d: "M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z" }), _jsx("path", { d: "M12 8v4" }), _jsx("path", { d: "M12 16h.01" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "a", children: _jsx("path", { fill: "#fff", d: "M0 0h24v24H0z" }) }) })] }));
};
const ForwardRef = forwardRef(SvgAlertCircle);
export default ForwardRef;
