import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { forwardRef } from 'react';
import { IconContext } from '../context';
const SvgChevronDown = (props, ref) => {
    const iconContext = React.useContext(IconContext);
    return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: props.size ?? iconContext.size ?? 24, height: props.size ?? iconContext.size ?? 24, fill: "none", viewBox: "0 0 24 24", ref: ref, ...props, children: _jsx("path", { stroke: "currentcolor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2, d: "m6 9 6 6 6-6" }) }));
};
const ForwardRef = forwardRef(SvgChevronDown);
export default ForwardRef;
