import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import Icon from '../Icon';
const IconButton = (props) => (_jsx(Icon, { ...props, css: [
        props['onClick'] !== undefined && {
            'cursor': 'pointer',
            ':hover': {
                filter: 'brightness(1.2)',
            },
        },
        props.disabled && {
            color: props.disabledContentColor,
            backgroundColor: props.disabledContainerColor,
            cursor: 'not-allowed',
        },
    ] }));
export default IconButton;
