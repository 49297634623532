import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTheme } from '@emotion/react';
import { IconContext } from '@talismn/icons';
const Icon = ({ as = 'button', size = '4rem', containerColor = 'transparent', contentColor, ...props }) => {
    const theme = useTheme();
    contentColor = contentColor ?? theme.color.onBackground;
    const Component = as;
    return (_jsx(Component, { ...props, css: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 0,
            padding: 0,
            width: size,
            height: size,
            border: 'none',
            borderRadius: `calc(${size}/2)`,
            color: contentColor,
            backgroundColor: containerColor,
            overflow: 'hidden',
            transition: '.25s',
        }, children: _jsx(IconContext.Provider, { value: { size: `calc(${size} * 0.6)` }, children: props['children'] }) }));
};
export default Icon;
