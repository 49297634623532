import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTheme } from '@emotion/react';
import { useId } from 'react';
import { Text, Surface } from '../../atoms';
const TextInput = Object.assign(({ leadingLabel, trailingLabel, trailingIcon, trailingSupportingText, leadingSupportingText, containerClassName, isError, ...props }) => {
    const theme = useTheme();
    const inputId = useId();
    return (_jsxs("div", { className: containerClassName, children: [(leadingLabel || trailingLabel) && (_jsxs("div", { css: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '0.8rem',
                }, children: [_jsx(Text.BodySmall, { as: "label", htmlFor: inputId, children: leadingLabel }), _jsx(Text.BodySmall, { as: "label", htmlFor: inputId, children: trailingLabel })] })), _jsxs(Surface, { css: {
                    display: 'flex',
                    alignItems: 'center',
                    padding: '1.5rem',
                    borderRadius: '1.25rem',
                    backgroundColor: theme.color.foreground,
                }, children: [_jsx(Text.Body, { ...props, as: "input", id: inputId, css: {
                            'flex': 1,
                            'width': props.width ?? '20rem',
                            'background': 'transparent',
                            'border': 'none',
                            '&[type=number]': {
                                '::-webkit-outer-spin-button': { display: 'none' },
                                '::-webkit-inner-spin-button': { display: 'none' },
                                '-moz-appearance': 'textfield',
                            },
                        } }), trailingIcon] }), (leadingSupportingText || trailingSupportingText) && (_jsxs("div", { css: {
                    'display': 'flex',
                    'justifyContent': 'space-between',
                    'alignItems': 'center',
                    'marginTop': '0.8rem',
                    '> *:empty::after': {
                        content: `"\u200B"`,
                    },
                }, children: [_jsx(Text.BodySmall, { as: "label", htmlFor: inputId, children: leadingSupportingText }), _jsx(Text.BodySmall, { as: "label", htmlFor: inputId, css: isError && { color: theme.color.onErrorContainer }, children: trailingSupportingText })] }))] }));
}, {
    ErrorLabel: (props) => (_jsx(Text.BodySmall, { color: theme => theme.color.onErrorContainer, ...props })),
    LabelButton: (props) => {
        const theme = useTheme();
        return (_jsx("button", { ...props, css: {
                'padding': '0.5rem',
                'border': 'none',
                'borderRadius': '1rem',
                'background': theme.color.foregroundVariant,
                'cursor': 'pointer',
                ':hover': {
                    filter: 'brightness(1.4)',
                },
            } }));
    },
});
export default TextInput;
