import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTheme } from '@emotion/react';
import { Tooltip } from '../../atoms';
import FloatingActionButton from '../../atoms/FloatingActionButton';
export const NavigationRailItem = (props) => {
    const theme = useTheme();
    return (_jsx(Tooltip, { content: props.label, children: _jsx(FloatingActionButton
        // @ts-expect-error
        , { 
            // @ts-expect-error
            as: "li", "aria-label": props.label, containerColor: theme.color.surface, hoverContainerColor: theme.color.onSurface, contentColor: theme.color.onSurface, hoverContentColor: theme.color.surface, onClick: props.onClick, children: props.icon }) }));
};
const NavigationRail = Object.assign((props) => {
    const theme = useTheme();
    return (_jsxs("nav", { css: [
            {
                display: 'flex',
                flexDirection: 'column-reverse',
                justifyContent: 'space-between',
                gap: '2rem',
                alignItems: 'center',
                borderRadius: '1.6rem',
                padding: '4.8rem 2.2rem',
                backgroundColor: theme.color.surface,
                width: 'fit-content',
                minHeight: '70vh',
                maxHeight: '90vh',
            },
            props.header === undefined && {
                minHeight: 'unset',
            },
        ], children: [_jsx("header", { children: props.header }), _jsx("ul", { css: {
                    listStyle: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '1.2rem',
                    padding: 0,
                }, children: props.children })] }));
}, { Item: NavigationRailItem });
export default NavigationRail;
