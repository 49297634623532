import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTheme } from '@emotion/react';
import { ChevronRight } from '@talismn/icons';
import { motion } from 'framer-motion';
import { createContext, useCallback, useState, } from 'react';
import React from 'react';
import { Text } from '../../atoms';
const DetailsContext = createContext({ onClick: (() => { }) });
const Details = Object.assign((props) => {
    const theme = useTheme();
    const [_open, setOpen] = useState(false);
    const open = props.open ?? _open;
    return (_jsxs(motion.details, { ...props, open: true, animate: JSON.stringify(open), initial: JSON.stringify(false), css: {
            borderRadius: '1.6rem',
            backgroundColor: theme.color.surface,
        }, onToggle: useCallback(event => event.preventDefault(), []), children: [_jsx(DetailsContext.Provider, { value: {
                    onClick: useCallback(event => {
                        event.preventDefault();
                        props.onToggle?.(!open);
                        setOpen(!open);
                    }, [open, props]),
                }, children: React.Children.toArray(props.children).at(0) }), React.Children.toArray(props.children).at(1)] }));
}, {
    Summary: (props) => (_jsx(DetailsContext.Consumer, { children: ({ onClick }) => (_jsxs("summary", { className: props.className, onClick: onClick, css: {
                'listStyle': 'none',
                'display': 'flex',
                'alignItems': 'center',
                'gap': '1.6rem',
                'padding': '2.2rem 3.2rem',
                'cursor': 'pointer',
                '::-webkit-details-marker': {
                    display: 'none',
                },
            }, children: [_jsx(Text.Body, { as: "span", alpha: "high", css: { flex: 1, marginRight: '2rem' }, children: props.children }), _jsx(motion.div, { variants: { true: { transform: 'rotate(90deg)' } }, children: _jsx(ChevronRight, { className: "marker" }) })] })) })),
    Content: (props) => (_jsx(motion.div, { className: props.className, variants: { true: { opacity: 1, height: 'auto' }, false: { opacity: 0, height: 0 } }, css: { overflow: 'hidden' }, children: _jsx(Text.Body, { as: "div", css: { padding: '0 3.2rem 2.2rem 3.2rem' }, children: props.children }) })),
});
export const OrderedDetailsList = (props) => (_jsx("ul", { ...props, css: {
        'counterReset': 'details-list',
        'margin': 0,
        'padding': 0,
        '> * + *': {
            marginTop: '1.6rem',
        },
        'summary': {
            '::before': {
                counterIncrement: 'details-list',
                content: 'counter(details-list)',
            },
        },
    } }));
export default Details;
