import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { forwardRef } from 'react';
import { IconContext } from '../context';
const SvgLink = (props, ref) => {
    const iconContext = React.useContext(IconContext);
    return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: props.size ?? iconContext.size ?? 24, height: props.size ?? iconContext.size ?? 24, fill: "none", viewBox: "0 0 24 24", ref: ref, ...props, children: [_jsx("path", { stroke: "currentcolor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2, d: "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" }), _jsx("path", { stroke: "currentcolor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2, d: "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" })] }));
};
const ForwardRef = forwardRef(SvgLink);
export default ForwardRef;
