import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useMemo } from 'react';
import CountUp from 'react-countup';
const AnimatedNumber = ({ animate = true, ...props }) => {
    const formatter = useMemo(() => {
        if (props.formatter === undefined) {
            return;
        }
        return props.formatter instanceof Intl.NumberFormat ? props.formatter.format.bind(props.formatter) : props.formatter;
    }, [props.formatter]);
    return _jsx(CountUp, { start: animate ? 0 : props.end, duration: 0.4, preserveValue: true, formattingFn: formatter, ...props });
};
export default AnimatedNumber;
