import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { forwardRef } from 'react';
import { IconContext } from '../context';
const SvgList = (props, ref) => {
    const iconContext = React.useContext(IconContext);
    return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: props.size ?? iconContext.size ?? 24, height: props.size ?? iconContext.size ?? 24, fill: "none", viewBox: "0 0 24 24", ref: ref, ...props, children: [_jsxs("g", { stroke: "currentcolor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2, clipPath: "url(#a)", children: [_jsx("path", { d: "M8 6h13" }), _jsx("path", { d: "M8 12h13" }), _jsx("path", { d: "M8 18h13" }), _jsx("path", { d: "M3 6h.01" }), _jsx("path", { d: "M3 12h.01" }), _jsx("path", { d: "M3 18h.01" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "a", children: _jsx("path", { fill: "#fff", d: "M0 0h24v24H0z" }) }) })] }));
};
const ForwardRef = forwardRef(SvgList);
export default ForwardRef;
