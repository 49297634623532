import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTheme } from '@emotion/react';
import { createContext, useContext, forwardRef, } from 'react';
const SurfaceElevationContext = createContext(0);
export const useSurfaceColorAtElevation = (elevation) => {
    const theme = useTheme();
    const currentElevation = useContext(SurfaceElevationContext);
    const elevationValue = typeof elevation === 'function' ? elevation(currentElevation) : elevation;
    return `color-mix(in srgb, ${theme.color.surface}, ${theme.color.surfaceTint} calc(4.75% * ${elevationValue}))`;
};
export const useSurfaceColor = () => useSurfaceColorAtElevation(useContext(SurfaceElevationContext));
const Surface = forwardRef(({ as = 'div', elevation: propsElevation, ...props }, ref) => {
    const Element = as;
    const currentElevation = useContext(SurfaceElevationContext);
    const elevation = typeof propsElevation === 'function' ? propsElevation(currentElevation) : propsElevation ?? currentElevation;
    const backgroundColor = useSurfaceColorAtElevation(elevation);
    return (_jsx(Element, { ref: ref, ...props, css: { backgroundColor }, children: props['children'] && (_jsx(SurfaceElevationContext.Provider, { value: elevation + 1, children: props['children'] })) }));
});
export default Surface;
