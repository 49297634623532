import mime from 'mime';
import { useEffect, useState } from 'react';
// Quick temporary solution for now
// a UI library should probably not have caching logic
const storageKey = (url) => `@talismn/ui/mime/${url}`;
export const useMimeType = (src, fetchMime = false) => {
    const referenceSrc = typeof src === 'string' ? src : src?.at(0);
    const contentTypeFromSrc = mime.getType(referenceSrc ?? '');
    const [mimeType, setMimeType] = useState(contentTypeFromSrc === null
        ? sessionStorage.getItem(storageKey(referenceSrc ?? '')) ?? undefined
        : contentTypeFromSrc);
    useEffect(() => {
        if (!fetchMime || mimeType !== undefined) {
            return;
        }
        const srcs = typeof src === 'string' ? [src] : src;
        if (srcs === undefined) {
            return;
        }
        void (async () => {
            for (const url of srcs) {
                try {
                    const response = await fetch(url, { method: 'HEAD' });
                    if (!response.ok) {
                        continue;
                    }
                    const contentType = response.headers.get('Content-Type');
                    setMimeType(contentType ?? undefined);
                    if (referenceSrc !== undefined && contentType !== null) {
                        sessionStorage.setItem(storageKey(referenceSrc), contentType);
                    }
                    break;
                }
                catch { }
            }
        })();
    }, [fetchMime, mimeType, referenceSrc, src]);
    const [type, subType] = (mimeType?.split('/') ?? [undefined, undefined]);
    return [type, subType];
};
export default useMimeType;
