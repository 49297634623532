import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTheme } from '@emotion/react';
import { motion } from 'framer-motion';
import { createContext, useContext } from 'react';
import { Text } from '../..';
const TabsContext = createContext({ noBottomBorder: false });
const Tab = ({ as = 'li', ...props }) => {
    const theme = useTheme();
    const Element = as;
    const { noBottomBorder } = useContext(TabsContext);
    return (_jsxs(Element, { ...props, css: {
            position: 'relative',
            padding: '0.8rem 0',
            cursor: 'pointer',
        }, children: [_jsx(Text.BodyLarge, { color: props.selected ? theme.color.primary : undefined, alpha: props.selected ? 'high' : 'medium', css: { fontSize: '1.8rem', margin: 0 }, children: props.children }), !noBottomBorder && props.selected && (_jsx(motion.div, { layoutId: "foo", css: { position: 'absolute', right: 0, bottom: -1, left: 0, height: 1, backgroundColor: theme.color.primary } }))] }));
};
const Tabs = Object.assign(({ noBottomBorder, ...props }) => {
    const theme = useTheme();
    return (_jsx("ul", { ...props, css: {
            display: 'flex',
            gap: '2.4rem',
            margin: 0,
            borderBottom: noBottomBorder ? undefined : `1px solid ${theme.color.border}`,
            padding: 0,
            listStyle: 'none',
        }, children: _jsx(TabsContext.Provider, { value: { noBottomBorder: noBottomBorder === true }, children: props.children }) }));
}, { Item: Tab });
export default Tabs;
