import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTheme } from '@emotion/react';
import { autoUpdate, flip, offset, shift, useClientPoint, useDismiss, useFloating, useFocus, useHover, useInteractions, useRole, } from '@floating-ui/react';
import { motion } from 'framer-motion';
import { useState } from 'react';
import FloatingPortal from '../FloatingPortal';
import Text from '../Text';
const Tooltip = ({ placement = 'right', ...props }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const { x, y, strategy, refs, context } = useFloating({
        open,
        onOpenChange: setOpen,
        placement,
        strategy: 'fixed',
        middleware: [offset({ mainAxis: 10, crossAxis: 10 }), flip(), shift()],
        whileElementsMounted: autoUpdate,
    });
    const { getReferenceProps, getFloatingProps } = useInteractions([
        useHover(context, { move: false }),
        useClientPoint(context),
        useFocus(context),
        useDismiss(context),
        useRole(context, { role: 'tooltip' }),
    ]);
    if (props.disabled) {
        return _jsx(_Fragment, { children: props.children });
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { ref: refs.setReference, css: { display: 'contents' }, ...getReferenceProps(), children: props.children }), _jsx(FloatingPortal, { children: open && Boolean(props.content) && (_jsx(motion.div, { ref: refs.setFloating, css: {
                        pointerEvents: 'none',
                        backgroundColor: theme.color.foregroundVariant,
                        padding: '0.6rem',
                        borderRadius: '0.4rem',
                        zIndex: 50,
                    }, style: {
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                        width: 'fit-content',
                    }, ...getFloatingProps(), children: _jsx(Text.Body, { as: "div", children: props.content }) })) })] }));
};
export default Tooltip;
