const greenDark = {
    color: {
        primary: '#d5ff5c',
        onPrimary: '#121212',
        primaryContainer: `color-mix(in srgb, #d5ff5c, transparent 88%)`,
        onPrimaryContainer: '#d5ff5c',
        background: '#121212',
        onBackground: '#fafafa',
        offWhite: '#f2f2f2',
        lightGrey: '#a5a5a5',
        surface: '#1B1B1B',
        onSurface: '#fafafa',
        surfaceTint: '#fafafa',
        error: '#d22424',
        onError: '#fafafa',
        errorContainer: '#fd48483e',
        onErrorContainer: '#d22424',
        foreground: '#262626',
        onForeground: '#fafafa',
        foregroundVariant: '#3f3f3f',
        onForegroundVariant: '#fafafa',
        border: '#2F2F2F',
    },
    contentAlpha: {
        disabled: 0.5,
        medium: 0.7,
        high: 1,
    },
};
export const theme = { greenDark };
