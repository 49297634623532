import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Box, Unknown, Video, Volume2 } from '@talismn/icons';
import React, { useState } from 'react';
import { useMimeType } from '../../utils';
const css = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    inset: 0,
    margin: 0,
    width: '100%',
    height: '100%',
};
const imgCss = [css, { objectFit: 'cover' }];
const SyncPreview = (props) => {
    const srcs = typeof props.src === 'string' ? [props.src] : props.src;
    switch (props.type) {
        case 'image':
            return (_jsxs("picture", { children: [srcs?.map((x, index) => (_jsx("source", { srcSet: x }, index))), _jsx("img", { css: imgCss, alt: 'Preview' })] }));
        case 'video':
            return (_jsx("figure", { css: css, children: _jsx(Video, { size: "40%" }) }));
        case 'audio':
            return (_jsx("figure", { css: css, children: _jsx(Volume2, { size: "40%" }) }));
        case 'model':
            return (_jsx("figure", { css: css, children: _jsx(Box, { size: "40%" }) }));
        default:
            return (_jsx("figure", { css: css, children: _jsx(Unknown, { size: "40%" }) }));
    }
};
const AsyncPreview = (props) => {
    const [type] = useMimeType(props.src, true);
    return _jsx(SyncPreview, { type: type, src: props.src });
};
export const Preview = (props) => {
    const [_type] = useMimeType(props.src);
    const type = props.type ?? _type;
    const srcs = typeof props.src === 'string' ? [props.src] : props.src;
    const [shouldTryFetchingMimeType, setShouldTryFetchingMimeType] = useState(false);
    if ((srcs?.length ?? 0) === 0) {
        return _jsx(SyncPreview, { src: props.src, type: undefined });
    }
    if (type !== undefined) {
        return _jsx(SyncPreview, { src: props.src, type: type });
    }
    if (shouldTryFetchingMimeType) {
        return props.fetchMime ? _jsx(AsyncPreview, { src: props.src }) : _jsx(SyncPreview, { src: props.src, type: type });
    }
    return (_jsxs("picture", { children: [srcs?.map((x, index) => (_jsx("source", { srcSet: x }, index))), _jsx("img", { onError: () => setShouldTryFetchingMimeType(true), css: imgCss, alt: "Preview" })] }));
};
export const MultiPreview = (props) => (_jsx("div", { css: { display: 'flex', height: '100%' }, children: _jsx("div", { css: {
            display: 'grid',
            gridTemplateRows: '1fr 1fr',
            gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
            gap: '1.6rem',
            margin: '1.6rem',
            width: 'stretch',
            alignSelf: 'stretch',
        }, children: React.Children.map(props.children, (child, index) => (_jsx("div", { css: { position: 'relative', borderRadius: '0.8rem', overflow: 'hidden' }, children: child }, index))) }) }));
