import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { forwardRef } from 'react';
import { IconContext } from '../context';
const Vote = (props, ref) => {
    const iconContext = React.useContext(IconContext);
    return (_jsxs("svg", { viewBox: "0 0 29 28", fill: "none", xmlns: "http://www.w3.org/2000/svg", width: props.size ?? iconContext.size ?? 24, height: props.size ?? iconContext.size ?? 24, ref: ref, ...props, children: [_jsx("path", { d: "M25.388 14.856V24.89H3.611l.023-9.298", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M17.298 6.526h4.68l3.41 8.264v.913H3.613v-.914l3.443-8.263h4.648", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M9.335 10.853h10.33", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M17.298 3.111h-5.595v7.742h5.595V3.111z", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] }));
};
const ForwardRef = forwardRef(Vote);
export default ForwardRef;
