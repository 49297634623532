import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTheme } from '@emotion/react';
import { Button, IconButton, Text } from '../../atoms';
export const TopAppBarItem = (props) => (_jsx(Button, { as: "li", variant: "noop", onClick: props.onClick, children: _jsxs("div", { css: { display: 'flex', flexDirection: 'column', alignItems: 'center' }, children: [_jsx(IconButton, { css: { height: '4rem', pointerEvents: 'none' }, children: props.icon }), _jsx(Text.BodySmall, { css: { textAlign: 'center' }, children: props.label })] }) }));
const TopAppBar = Object.assign((props) => {
    const theme = useTheme();
    return (_jsxs("section", { css: {
            listStyle: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '1.2rem',
            width: '100%',
            padding: '1.6rem 2.4rem',
            backgroundColor: theme.color.background,
        }, children: [props.navigationIcon, props.actions] }));
}, {
    Item: TopAppBarItem,
    Actions: (props) => (_jsx("div", { ...props, css: { display: 'flex', alignItems: 'center', gap: '1.8rem' } })),
});
export default TopAppBar;
