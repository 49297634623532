import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { Global, keyframes, useTheme } from '@emotion/react';
import { Volume2, X } from '@talismn/icons';
import { Button, Dialog, Text } from '../../atoms';
import { useMimeType } from '../../utils';
const show = keyframes `
  from {
    opacity: 0;
    transform: translateY(2rem);
  }
`;
const backdropKeyframes = keyframes `
  from {
    background: rgba(0,0,0,0);
    backdrop-filter: blur(0);
  }
  to {
    background: rgba(0,0,0,0.6);
    backdrop-filter: blur(16px);
  }
`;
const MediaPlayer = (props) => {
    const [_type, _subType] = useMimeType(props.src, props.type === undefined);
    const type = props.type ?? _type;
    const subType = props.subType ?? _subType;
    switch (type) {
        case 'image':
            return (_jsx("img", { src: props.src, css: { width: '100%', height: '100%', objectFit: 'contain' }, alt: props.subType + ' media player' }));
        case 'video':
            return _jsx("video", { src: props.src, css: { width: '100%', height: '100%' }, controls: true });
        case 'audio':
            return (_jsxs("div", { css: { position: 'relative', height: '100%' }, children: [_jsx(Volume2, { size: "40%", css: { position: 'absolute', inset: 0, margin: 'auto' } }), _jsx("audio", { src: props.src, css: { width: '100%', height: '100%' }, controls: true })] }));
        case 'model':
            import('@google/model-viewer/dist/model-viewer');
            return (_jsx("model-viewer", { src: props.src, "camera-controls": true, 
                // @ts-expect-error
                style: { width: '100%', height: '100%' } }));
        case 'application':
            if (subType === 'pdf') {
                return _jsx("embed", { src: props.src, css: { width: '100%', height: '100%' } });
            }
            return null;
        case null:
        case undefined:
        default:
            return null;
    }
};
const MediaDialog = Object.assign(({ title, overline, media, content, onRequestDismiss, ...props }) => {
    const theme = useTheme();
    return (_jsxs(_Fragment, { children: [props.open && _jsx(Global, { styles: { body: { overflow: 'hidden' } } }), _jsxs(Dialog, { ...props, title: undefined, onClickBackdrop: onRequestDismiss, onClose: onRequestDismiss, onCancel: onRequestDismiss, css: {
                    'display': 'grid',
                    'gridTemplateAreas': `
            'media'
            'header'
            'content'
          `,
                    'gridTemplateRows': 'repeat(3, min-content)',
                    'margin': 0,
                    'width': 'auto',
                    'maxWidth': '100%',
                    'height': 'auto',
                    'maxHeight': '100%',
                    'border': 'none',
                    'padding': 0,
                    'background': theme.color.surface,
                    'overflow': 'auto',
                    '&[open]': {
                        'animation': `${show} .5s ease`,
                        '::backdrop': {
                            background: 'rgba(0,0,0,0.6)',
                            backdropFilter: 'blur(16px)',
                            animation: `${backdropKeyframes} .5s ease forwards`,
                        },
                    },
                    '@media(min-width: 1024px)': {
                        gridTemplateAreas: `
              'media header'
              'media content'
            `,
                        gridTemplateRows: 'min-content minmax(0, 1fr)',
                        gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
                        margin: 'auto',
                        width: '80%',
                        maxWidth: 'revert',
                        height: 'revert',
                        maxHeight: 'revert',
                        borderRadius: '2.4rem',
                        overflow: 'hidden',
                    },
                }, children: [_jsxs("div", { css: {
                            gridArea: 'header',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'baseline',
                            padding: '2.4rem',
                        }, children: [_jsxs("header", { children: [_jsx(Text.H4, { alpha: "medium", css: { margin: '0 0 0.4rem 0' }, children: overline }), _jsx(Text.H3, { css: { margin: 0 }, children: title })] }), _jsx(Button, { variant: "noop", onClick: onRequestDismiss, children: _jsx(X, { size: "2.4rem" }) })] }), _jsx("div", { css: { gridArea: 'media', aspectRatio: '1 / 1' }, children: media }), _jsx("div", { css: { gridArea: 'content', display: 'flex', flexDirection: 'column' }, children: _jsx("div", { css: { 'padding': '2.4rem', '@media(min-width: 1024px)': { flex: '1 1 0', overflow: 'auto' } }, children: content }) })] })] }));
}, { Player: MediaPlayer });
export default MediaDialog;
