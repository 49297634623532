import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { keyframes, useTheme } from '@emotion/react';
import { X } from '@talismn/icons';
import { Dialog, IconButton, Text } from '../../atoms';
import { Toaster } from '../../organisms';
import { useMediaQuery } from '../../utils';
const slideInRight = keyframes `
  from {
    transform: translateX(100%);
  }
`;
const slideUp = keyframes `
  from {
    transform: translateY(100%);
  }
`;
const backdropKeyframes = keyframes `
  from {
    background: rgba(0,0,0,0);
    backdrop-filter: blur(0);
  }
  to {
    background: rgba(0,0,0,0.6);
    backdrop-filter: blur(16px);
  }
`;
export const SIDE_SHEET_WIDE_BREAK_POINT = '768px';
export const SIDE_SHEET_WIDE_BREAK_POINT_SELECTOR = `@media(min-width: ${SIDE_SHEET_WIDE_BREAK_POINT})`;
const SideSheet = ({ title, children, onRequestDismiss, ...rest }) => {
    const theme = useTheme();
    return (_jsxs(Dialog, { ...rest, onClickBackdrop: onRequestDismiss, css: {
            'background': theme.color.background,
            'border': 'none',
            'width': '100%',
            'maxWidth': '100%',
            'height': '100%',
            'maxHeight': '100%',
            'padding': '2.4rem',
            '&[open]': {
                'animation': `${slideUp} .5s`,
                '::backdrop': {
                    background: 'rgba(0,0,0,0.6)',
                    backdropFilter: 'blur(16px)',
                    animation: `${backdropKeyframes} .5s forwards`,
                },
            },
            [`${SIDE_SHEET_WIDE_BREAK_POINT_SELECTOR}`]: {
                'width': 'min-content',
                'marginLeft': 'auto',
                'marginRight': 0,
                'padding': '4.8rem',
                '&[open]': {
                    animation: `${slideInRight} .5s`,
                },
            },
        }, children: [_jsxs("header", { css: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '1rem',
                    marginBottom: '5.2rem',
                    [`${SIDE_SHEET_WIDE_BREAK_POINT_SELECTOR}`]: {
                        marginBottom: '4.8rem',
                    },
                }, children: [_jsx(Text.H2, { css: { margin: 0 }, children: title }), _jsx(IconButton, { onClick: onRequestDismiss, children: _jsx(X, {}) })] }), children, _jsx(Toaster, { position: useMediaQuery(SIDE_SHEET_WIDE_BREAK_POINT_SELECTOR) ? 'bottom-right' : 'bottom-center' })] }));
};
export default SideSheet;
