import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useReducedMotion } from 'framer-motion';
import { useCallback } from 'react';
import { resolveValue, useToaster } from 'react-hot-toast/headless';
import { ToastBar } from '../molecules';
const ToastWrapper = ({ id, className, style, onHeightUpdate, children }) => (_jsx("div", { ref: useCallback(element => {
        if (element !== null) {
            const updateHeight = () => {
                const height = element.getBoundingClientRect().height;
                onHeightUpdate(id, height);
            };
            updateHeight();
            new MutationObserver(updateHeight).observe(element, {
                subtree: true,
                childList: true,
                characterData: true,
            });
        }
    }, [id, onHeightUpdate]), className: className, style: style, children: children }));
const getPositionStyle = (position, offset, prefersReducedMotion) => {
    const top = position.includes('top');
    return [
        {
            left: 0,
            right: 0,
            display: 'flex',
            position: 'absolute',
            transition: prefersReducedMotion ? undefined : `all 230ms cubic-bezier(.21,1.02,.73,1)`,
            transform: `translateY(${offset * (top ? 1 : -1)}px)`,
        },
        top ? { top: 0 } : { bottom: 0 },
        position.includes('center')
            ? {
                justifyContent: 'center',
            }
            : position.includes('right')
                ? {
                    justifyContent: 'flex-end',
                }
                : {},
    ];
};
export const TOASTER_DEFAULT_OFFSET = 16;
const Toaster = ({ className, position = 'top-center', reverseOrder, toastOptions, gutter, children, }) => {
    const { toasts, handlers } = useToaster(toastOptions);
    const prefersReducedMotion = useReducedMotion();
    return (_jsx("dialog", { open: true, className: className, onMouseEnter: handlers.startPause, onMouseLeave: handlers.endPause, css: {
            position: 'fixed',
            inset: TOASTER_DEFAULT_OFFSET,
            margin: 0,
            border: 'none',
            width: 'auto',
            height: 'auto',
            background: 'none',
            pointerEvents: 'none',
        }, children: toasts.map(t => {
            const offset = handlers.calculateOffset(t, {
                reverseOrder,
                gutter,
                defaultPosition: position,
            });
            const positionStyle = getPositionStyle(position, offset, prefersReducedMotion ?? false);
            return (_jsx(ToastWrapper, { id: t.id, onHeightUpdate: handlers.updateHeight, css: [
                    t.visible && {
                        'zIndex': 9999,
                        '> *': {
                            pointerEvents: 'auto',
                        },
                    },
                    positionStyle,
                ], children: t.type === 'custom' ? (resolveValue(t.message, t)) : children ? (children(t)) : (_jsx(ToastBar, { toast: t, position: position })) }, t.id));
        }) }));
};
export default Toaster;
export { toast } from 'react-hot-toast/headless';
