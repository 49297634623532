import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { forwardRef } from 'react';
import { IconContext } from '../context';
const SvgVideo = (props, ref) => {
    const iconContext = React.useContext(IconContext);
    return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: props.size ?? iconContext.size ?? 24, height: props.size ?? iconContext.size ?? 24, fill: "none", viewBox: "0 0 24 24", ref: ref, ...props, children: [_jsx("path", { stroke: "currentcolor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2, d: "m23 7-7 5 7 5V7Z" }), _jsx("path", { stroke: "currentcolor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2, d: "M14 5H3a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2Z" })] }));
};
const ForwardRef = forwardRef(SvgVideo);
export default ForwardRef;
