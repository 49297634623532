import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useId } from 'react';
import { Surface, Text } from '../../atoms';
const ContainedTextInput = Object.assign(({ leadingLabel, trailingLabel, leadingIcon, trailingIcon, hasSupportingText, trailingSupportingText, leadingSupportingText, width, ...props }) => {
    const inputId = useId();
    return (_jsxs(Surface, { css: {
            padding: '1.5rem',
            borderRadius: '1.25rem',
            width,
        }, children: [(leadingLabel || trailingLabel) && (_jsxs("div", { css: {
                    'display': 'flex',
                    'justifyContent': 'space-between',
                    'alignItems': 'center',
                    '> *:empty::after': {
                        content: `"\u200B"`,
                    },
                }, children: [_jsx(Text.BodySmall, { as: "label", htmlFor: inputId, children: leadingLabel }), _jsx(Text.BodySmall, { as: "label", htmlFor: inputId, children: trailingLabel })] })), _jsxs("div", { css: {
                    display: 'flex',
                    alignItems: 'center',
                    margin: '1.6rem 0',
                }, children: [leadingIcon, _jsx(Text.BodyLarge, { ...props, as: "input", id: inputId, alpha: "high", css: {
                            'flex': 1,
                            // If container width is defined set width to 0 to expand with flex
                            'width': width !== undefined ? 0 : '20rem',
                            'border': 'none',
                            'background': 'transparent',
                            'fontSize': '3.2rem',
                            'textAlign': 'end',
                            '&[type=number]': {
                                '::-webkit-outer-spin-button': { display: 'none' },
                                '::-webkit-inner-spin-button': { display: 'none' },
                                '-moz-appearance': 'textfield',
                            },
                        } }), trailingIcon] }), (hasSupportingText || leadingSupportingText || trailingSupportingText) && (_jsxs("div", { css: {
                    'display': 'flex',
                    'justifyContent': 'space-between',
                    'alignItems': 'center',
                    '> *:empty::after': {
                        content: `"\u200B"`,
                    },
                }, children: [_jsx(Text.BodySmall, { as: "label", htmlFor: inputId, children: leadingSupportingText }), _jsx(Text.BodySmall, { as: "label", htmlFor: inputId, children: trailingSupportingText })] }))] }));
}, {
    ErrorLabel: (props) => _jsx(Text.BodySmall, { color: theme => theme.color.onError, ...props }),
});
export default ContainedTextInput;
