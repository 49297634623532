import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTheme } from '@emotion/react';
import { Button, IconButton, Text } from '../../atoms';
export const NavigationBarItem = (props) => (_jsx(Button, { as: "li", variant: "noop", onClick: props.onClick, children: _jsxs("div", { css: { display: 'flex', flexDirection: 'column', alignItems: 'center' }, children: [_jsx(IconButton, { css: { height: '4rem', pointerEvents: 'none' }, children: props.icon }), _jsx(Text.BodySmall, { css: { textAlign: 'center' }, children: props.label })] }) }));
const NavigationBar = Object.assign((props) => {
    const theme = useTheme();
    return (_jsx("ul", { css: {
            listStyle: 'none',
            display: 'flex',
            justifyContent: 'space-evenly',
            gap: '1.2rem',
            width: '100%',
            margin: 0,
            borderTop: `1px solid ${theme.color.border}`,
            padding: '1.2rem 2.35rem',
            backgroundColor: theme.color.background,
        }, children: props.children }));
}, { Item: NavigationBarItem });
export default NavigationBar;
