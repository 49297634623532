import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTheme } from '@emotion/react';
const useAlpha = (color, alpha) => {
    const theme = useTheme();
    const parsedColor = typeof color === 'string' ? color : color(theme);
    const alphaValue = theme.contentAlpha[alpha ?? 'medium'];
    return `color-mix(in srgb, ${parsedColor}, transparent ${Math.round((1 - alphaValue) * 100)}%)`;
};
const NoopText = ({ as, color: _color, alpha: _alpha, ...props }) => {
    const Component = as ?? 'span';
    return _jsx(Component, { ...props });
};
const BaseText = ({ as, color: _color, alpha = 'medium', ...props }) => {
    const theme = useTheme();
    const Component = as ?? 'span';
    const color = _color ?? theme.color.onBackground;
    return (_jsx(Component, { ...props, css: {
            'color': useAlpha(color, typeof alpha === 'function' ? alpha({ hover: false }) : alpha),
            'fontFamily': "'Surt', sans-serif",
            ':hover': {
                color: useAlpha(color, typeof alpha === 'function' ? alpha({ hover: true }) : alpha),
            },
        } }));
};
const BaseHeaderText = ({ as, color: _color, alpha = 'high', ...props }) => {
    const theme = useTheme();
    const Component = as ?? 'span';
    const color = _color ?? theme.color.onBackground;
    return (_jsx(Component, { ...props, css: {
            'color': useAlpha(color, typeof alpha === 'function' ? alpha({ hover: false }) : alpha),
            'fontFamily': "'SurtExpanded', sans-serif",
            ':hover': {
                color: useAlpha(color, typeof alpha === 'function' ? alpha({ hover: true }) : alpha),
            },
        } }));
};
// TODO: the default props handling & component decoration can be improved
const defaultProps = (props) => ({
    H1: { as: props.as ?? 'h1', css: { fontSize: 56 } },
    H2: { as: props.as ?? 'h2', css: { fontSize: 32 } },
    H3: { as: props.as ?? 'h3', css: { fontSize: 24 } },
    H4: { as: props.as ?? 'h4', css: { fontSize: 18 } },
    BodyLarge: { as: props.as ?? 'span', css: { fontSize: 16, fontWeight: 'normal', margin: 0 } },
    Body: { as: props.as ?? 'span', css: { fontSize: 14, fontWeight: 'normal', margin: 0 } },
    BodySmall: { as: props.as ?? 'span', css: { fontSize: 12, fontWeight: 'normal', margin: 0 } },
    Noop: { as: undefined, css: {} },
});
const decorateText = (tag, element) => Object.assign(element, {
    A: (props) => (_jsx(BaseText, { ...defaultProps(props)[tag], as: "a", alpha: "high", ...props, css: [defaultProps(props)[tag].css, { textDecoration: 'underline' }] })),
    Redacted: (props) => (_jsx(BaseText, { ...defaultProps(props)[tag], as: "del", alpha: "disabled", ...props, css: [
            defaultProps(props)[tag].css,
            {
                'color': 'transparent',
                'borderRadius': '0.5em',
                'background': 'radial-gradient(rgba(200, 200, 200, 0.1), rgba(0, 0, 0, 0.1))',
                'filter': 'contrast(0.5) brightness(1.5)',
                ':hover': {
                    color: 'transparent',
                },
            },
        ] })),
});
const Text = Object.assign(BaseText, {
    H1: decorateText('H1', (props) => (_jsx(BaseHeaderText, { ...props, ...defaultProps(props).H1 }))),
    H2: decorateText('H2', (props) => (_jsx(BaseHeaderText, { ...props, ...defaultProps(props).H2 }))),
    H3: decorateText('H3', (props) => (_jsx(BaseHeaderText, { ...props, ...defaultProps(props).H3 }))),
    H4: decorateText('H4', (props) => (_jsx(BaseHeaderText, { ...props, ...defaultProps(props).H4 }))),
    BodyLarge: decorateText('BodyLarge', (props) => (_jsx(BaseText, { ...props, ...defaultProps(props).BodyLarge }))),
    Body: decorateText('Body', (props) => (_jsx(BaseText, { ...props, ...defaultProps(props).Body }))),
    BodySmall: decorateText('BodySmall', (props) => (_jsx(BaseText, { ...props, ...defaultProps(props).BodySmall }))),
    Noop: decorateText('Noop', NoopText),
});
export default Text;
