import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { forwardRef } from 'react';
import { IconContext } from '../context';
const SvgCopy = (props, ref) => {
    const iconContext = React.useContext(IconContext);
    return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: props.size ?? iconContext.size ?? 24, height: props.size ?? iconContext.size ?? 24, fill: "none", viewBox: "0 0 24 24", ref: ref, ...props, children: [_jsx("path", { stroke: "currentcolor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2, d: "M20 9h-9a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-9a2 2 0 0 0-2-2Z" }), _jsx("path", { stroke: "currentcolor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2, d: "M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" })] }));
};
const ForwardRef = forwardRef(SvgCopy);
export default ForwardRef;
